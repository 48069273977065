import React, { Component } from "react";
import InputField from "./../../elements/InputField";
import Button from "../../elements/Button";
import MasterService from "./../../../service/MasterService";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Select from "react-select";
import { DealerService } from "../../../service";

class ShareDocHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            errors: {},
            dealer_id_hash: this.props.dealer_id_hash,
            doc_options: [],
            loading: false,
        };
    }

    componentDidMount = async () => {
        await this.getDealerDocData();
    };

    getDealerDocData = async () => {
        try {
            let response = await DealerService.getKycMasterDetails();
            if (response && response.status === 200 && response.data.status === 200) {
                let respone_data = response.data.data;

                const all_entries = respone_data.flatMap((category) =>
                    category.child.map((child) => ({
                        value: String(child.kyc_child_id),
                        label: child.kyc_child_name,
                    }))
                );
                let unique_entries = Array.from(new Map(all_entries.map((entry) => [entry.value, entry])).values());
                unique_entries = unique_entries.filter((item) => this.props.available_docs.includes(parseInt(item.value)));
                this.setState({ doc_options: unique_entries });
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error);
        }
    };

    handleChangeMultiSelect = (selectedOption) => {
        let tempFormData = JSON.parse(JSON.stringify(this.state.formData));
        let tempErrors = JSON.parse(JSON.stringify(this.state.errors));
        tempFormData.docs_shared_list = selectedOption;
        tempErrors["docs_shared_list"] = "";
        this.setState({ formData: tempFormData, errors: tempErrors });
    };

    handleChange = (event) => {
        let { name, value } = event.target;
        let tempFormData = JSON.parse(JSON.stringify(this.state.formData));
        let tempErrors = JSON.parse(JSON.stringify(this.state.errors));
        tempFormData[name] = value;
        tempErrors[name] = "";
        this.setState({ formData: tempFormData, errors: tempErrors });
    };

    isValidForm = () => {
        let tempFormData = JSON.parse(JSON.stringify(this.state.formData));
        let tempErrors = JSON.parse(JSON.stringify(this.state.errors));
        let valid = true;
        if (!tempFormData["docs_shared_list"] || !tempFormData["docs_shared_list"].lenth === 0) {
            tempErrors["docs_shared_list"] = this.props.t("dealerDetails.kyc_details.validation_texts.docs_required");
            valid = false;
        }

        if (!tempFormData["shared_to_email"]) {
            tempErrors["shared_to_email"] = this.props.t("dealerDetails.kyc_details.validation_texts.email_required");
            valid = false;
        }

        if (tempFormData["shared_to_email"] && !tempFormData["shared_to_email"].match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            tempErrors["shared_to_email"] = this.props.t("dealerDetails.kyc_details.validation_texts.invalid_email");
            valid = false;
        }

        if (!tempFormData["reason_for_sharing"]) {
            tempErrors["reason_for_sharing"] = this.props.t("dealerDetails.kyc_details.validation_texts.reason_required");
            valid = false;
        }

        this.setState({ errors: tempErrors });
        return valid;
    };

    shareDealerDocs = async () => {
        this.setState({ loading: true });
        try {
            if (this.isValidForm()) {
                this.props.modalClose();
                toast.success("Documents shared successfully");
                await MasterService.post("/dealer/dealer/share_dealer_docs", { ...this.state.formData, dealer_id_hash: this.state.dealer_id_hash });
            }
        } catch (error) {
            toast.error(error);
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const { errors, formData, doc_options, loading } = this.state;
        let css = "";
        if (errors) {
            css = `.form-error {display:block}`;
        }
        return (
            <div className={loading ? "loading" : ""}>
                <style type="text/css">{css}</style>
                <div className="row">
                    <div className="col-sm-6 col-md-12 form-group">
                        <Select isMulti name="docs_shared_list" options={doc_options} onChange={this.handleChangeMultiSelect} value={formData.selected_doc} />
                        {errors && errors.docs_shared_list ? <span className="error show">{errors.docs_shared_list}</span> : ""}
                    </div>
                    <div className="col-sm-6 col-md-12 form-group">
                        <InputField
                            inputProps={{
                                id: "shared_to_email",
                                type: "email",
                                placeholder: this.props.t("dealerDetails.kyc_details.email_id"),
                                name: "shared_to_email",
                                label: this.props.t("dealerDetails.kyc_details.email_id"),
                                validation_error: errors.shared_to_email,
                            }}
                            value={formData.shared_to_email}
                            onChange={(e) => {
                                this.handleChange(e);
                            }}
                        />
                    </div>
                    <div className="col-sm-6 col-md-12 form-group text-left">
                        <label>{this.props.t("dealerDetails.kyc_details.reason")}</label>
                        <textarea
                            onChange={this.handleChange}
                            rows="2"
                            className="form-control"
                            name="reason_for_sharing"
                            value={formData.reason_for_sharing}
                        ></textarea>
                        {errors && errors.reason_for_sharing ? <span className="error show">{errors.reason_for_sharing}</span> : ""}
                    </div>

                    <div className="col-sm-6 col-md-12 form-group text-right">
                        <Button
                            onClick={this.props.modalClose}
                            colclass="col-sm-12"
                            btnClass="btn btn-link mrg-r15"
                            type="reset"
                            name="addleadbtn"
                            id="cancel"
                            title={this.props.t("dealerDetails.kyc_details.no")}
                        />
                        <Button
                            onClick={this.shareDealerDocs}
                            colclass="col-sm-12"
                            btnClass="btn btn-primary"
                            name="addleadbtn"
                            id="addleadbtn"
                            title={this.props.t("dealerDetails.kyc_details.yes")}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id,
    };
};

export default withTranslation("dealers")(connect(mapStateToProps)(ShareDocHistory));
