import React, { Component } from 'react';


class ModalPopup extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            //h4: props.h4 || "",
       showmodal : true,
        };
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
      }
    
      componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
      }
    
      componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
      }
    
      /**
       * Set the wrapper ref
       */
      setWrapperRef(node) {
        this.wrapperRef = node;
      }
    
      /**
       * Alert if clicked on outside of element
       */
      handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.props.modalClose()
        }
      
      }
    //   closeModal = () => {
    //     console.log("close");
    //   //const value = event.target.value;
    //   this.setState({ showmodal:false });
    // }
 
    
       
      
       
  render() {
    // const { showmodal, id,  } = this.state;//need to remove showmodal is not in use
    const { id,  } = this.state;
    // var { title, children } = this.props;//need to remove title is not in use
    var { children } = this.props;
   // const modalClassName = 
    return (
        
        <div>
          <div  className={this.props.className}  id={id}>
            <div className="modal-dialog"  ref={this.setWrapperRef}>
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title h4">{this.props.title}</div>
                        <button type="button" className="close" onClick={()=>{this.props.modalClose()}} ><span aria-hidden="true">×</span></button>
                    </div>
                    <div className="modal-body">{children}</div>
                    {/* <div className="modal-footer">
                        <button type="button" className="btn btn-link">Close</button>
                        <button type="button" className="btn btn-primary">Save Changes</button>
                    </div> */}
                </div>
            </div>
          </div>
          <div className='modal-backdrop' onClick={()=>{this.props.modalClose()}}></div>
        </div>
        
        
    )
  }
}
export default ModalPopup;
