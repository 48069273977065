import React, { Component } from 'react';
import InputField from '../../elements/InputField';
import OtpValidation from './otp-validate';
import ResetPass from './ResetPassword';
import {Redirect} from 'react-router-dom';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import MasterService from './../../../service/MasterService';
import {MOBILE_VALIDATION} from './../../../config/constant';
class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            forgot_step: 'step1',
            fields: {},
            errors: {},
            token:"",
            redirect:false
        }
    }
    
    validForm = ()=>{
        let fields = {...this.state.fields};
        let errors = {...this.state.errors};
        let formIsValid = true;
        let mobileRule = MOBILE_VALIDATION;
        if (!fields["mobile"]) {
            errors['mobile'] = this.props.t('joinus.enter_mobile');
            formIsValid = false;
        }else if(!mobileRule.test(fields['mobile'])){
            errors['mobile'] = this.props.t('joinus.valid_mobile');
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    submitForgotForm = (event) =>{
        var parentObj = this;
        event.preventDefault();
        if(this.validForm()){
            MasterService.post('account/user/send_otp', this.state.fields)
            .then(function (response) {
                if(response.status === 200 && response.data.status === 200){
                    // let responseData = response.data;//need to remove var not in use
                    parentObj.setState({forgot_step:'step2'});
                }else{
                    toast.error(response.data.message);
                }
            }).catch(function (error) {
                console.log(error.message);
            });
        }
    }

    handleChange = event =>{
        let fields = {...this.state.fields};
        let errors = {...this.state.errors};
        fields[event.target.name] = event.target.value;
        if(event.target.value !== ''){
            delete errors[event.target.name];
        }else{
            errors[event.target.name] = 'Please enter '+event.target.name;
        }
        this.setState({ fields: fields, errors: errors });
    }

    showLoginForm = event => {
        let showingSection = event.currentTarget.dataset.key;
        this.setState({
            displayForm: showingSection
        })
    }

    validateOTP = (event)=>{
        var parentObj = this;
        event.preventDefault();
        if(this.validOtpForm()){
            let field = {...this.state.fields,'kind':'reset-password'};
            MasterService.post('account/user/validate_otp', field)
            .then(function (response) {
                if(response.status === 200 && response.data.status === 200){
                    let responseData = response.data.data;
                    parentObj.setState({
                        token:responseData.token,
                        forgot_step:'step3'
                    });
                }else{
                    toast.error(response.data.message);
                }
            }).catch(function (error) {
                console.log(error.message);
            });
        }
    }

    validOtpForm = ()=>{
        let fields = {...this.state.fields};
        let errors = {...this.state.errors};
        let formIsValid = true;
        if (!fields["otp"]) {
            errors['otp'] = "Please enter otp";
            formIsValid = false;
        }
        this.setState({errors: errors})
        return formIsValid;
    }

    resetPWD = (event)=>{
        var parentObj = this;
        event.preventDefault();
        if(this.validPassForm()){
            let field = {...this.state.fields,'token':this.state.token};
            MasterService.post('account/user/set-password', field)
            .then(function (response) {
                if(response.status === 200){
                    // let responseData = response.data;//need to remove var not in use
                    parentObj.props.showEmailLoginForm();
                    toast.success(response.data.message);
                  //  parentObj.setState({redirect:true});
                }else{
                    toast.error(response.data.message);
                }
            }).catch(function (error) {
                console.log(error.message);
            });
        }
    }

    validPassForm = ()=>{
        let fields = {...this.state.fields};
        let errors = {...this.state.errors};
        let formIsValid = true;
        if (!fields["new_password"]) {
            errors['new_password'] = "Please enter new_password";
            formIsValid = false;
        }
        if (!fields["re_new_password"]) {
            errors['re_new_password'] = "Please enter re_new_password";
            formIsValid = false;
        }
        if (fields["new_password"] !== fields["re_new_password"]) {
            errors['re_new_password'] = "Password and confirm password not matched";
            formIsValid = false;
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    backToLogin = (event)=>{
        if (typeof this.props.showLoginForm === 'function') {
            this.props.showLoginForm(event);
        }
    }


    render() {
        if (this.state.redirect === true) {
            return (<Redirect to={'/login'} />);
        }
        const {errors} = this.state;
        return (
            <div className="item active">
                <div className="sm">
                    <div id="fedupDix" className="row os-animation animated fadeInUp" data-os-animation="fadeInUp" data-os-animation-delay="0.50s">
                        <div className="col-md-12" id="modified_design">
                        { this.state.forgot_step === 'step1'?
                            <div className="signUp-container text-left">
                                <h1 className="login-h1">{this.props.t('forgot.forgot')}</h1>
                                <p className="login-p">{this.props.t('forgot.forgot_title')}</p>
                                <form className="text-left os-animation animated fadeInDown" data-os-animation="fadeInDown" data-os-animation-delay="0s" method="post" id="loginform">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <InputField
                                                inputProps={{
                                                    id: "mobile",
                                                    type: "tel",
                                                    name: "mobile",
                                                    label:this.props.t('forgot.enter_mobile'),
                                                    dataerror: errors.mobile,
                                                    autoFocus:true,
                                                    maxLength: 12
                                                }}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="col-sm-12"><label className="mrg-B0 forgotP" id="forgotPassword"><a href={() => false} onClick={this.backToLogin} data-key="email">{this.props.t('login.back_to_login')}</a></label></div>
                                    </div>
                                    <span id="invalidLogin" className="invalid-login"></span>
                                    <div className="form-group">
                                        <button className="btn btn-primary btn-lg btn-block" id="sub-login" name="email_login" type="submit" onClick={this.submitForgotForm}>{this.props.t('forgot.send')}</button>
                                    </div>
                                </form>
                            </div>
                            :
                            this.state.forgot_step === 'step2'?
                                <div className="signUp-container text-left">
                                    <h1 className="login-h1">{this.props.t('forgot.forgot')}</h1>
                                    <p className="login-p">{this.props.t('forgot.enter_otp')}</p>
                                    <OtpValidation errors={this.state.errors} onClick={this.validateOTP} onChange={this.handleChange} />
                                </div>
                                :
                                <ResetPass errors={this.state.errors} onClick={this.resetPWD} onChange={this.handleChange} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation('common')(ForgotPassword);