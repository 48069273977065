import React, { Component } from "react";
import Button from '../../elements/Button'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';
import {getStatusChangeOptions, getStatusByValue} from "../../common/Utils"
import InputField from '../../elements/Input'

class ChangeStatusPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dealer_current_status: this.props.dealer_current_status,
            errors: {},
            selected_status: 0,
            status_options : getStatusChangeOptions(this.props.dealer_current_status),
            dealer_current_status_val: getStatusByValue(this.props.dealer_current_status),
            changeStatusPopupInfo: this.props.changeStatusPopupInfo
        };
    }
    submitStatusChange = async (event) => {
        event.preventDefault();
        var thisObj = this;
        if (this.validFormData()) {
            if (typeof thisObj.props.handleStatusChangeAction === 'function') {
                thisObj.props.handleStatusChangeAction(thisObj.state.selected_status);
            }
            thisObj.props.modalClose();
        }
    }
    validFormData = () => {
        let errors = {};
        let formIsValid = true;

        if(!this.state.selected_status){
            errors['selected_status'] = this.props.t('Listing.Status_Change_Popup.select_status');
            formIsValid = false;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleStatusChange = (sname, ovalue) => {
        this.setState({ selected_status: sname.value })
    }

    render() {
        const { status_options, selected_status, dealer_current_status_val, changeStatusPopupInfo } = this.state;
        const { errors } = this.state;
        
        return (
            <div className="changeStatus">
                <h3>{this.props.t('Listing.Status_Change_Popup.change_status')}</h3>
                <div className="changeStatusOrg">{changeStatusPopupInfo.org_name ? changeStatusPopupInfo.org_name : ""}{" "}{changeStatusPopupInfo.gcd ? "(" + changeStatusPopupInfo.gcd + ")" : ""}</div>
                <div className="row">
                <div className="col-sm-6 changeStatusCurValue">
                    <InputField id="current_status" value={dealer_current_status_val} name="current_status" label="From" type="text" placeholder="" />
                </div>
                <div className="col-sm-6 changeStatusCurOption">
                            <label>To</label>
                            <Select
                                components={{ IndicatorSeparator:() => null }}
                                id="reason_for_inactivate"
                                options={status_options}
                                value={status_options.filter(({ value }) => value === selected_status)}
                                onChange={this.handleStatusChange.bind(this)}
                                name="inactive_reason"
                                placeholder={this.props.t('Listing.Inactivate_Reason_Popup.Select_message_status')}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ value }) => value}
                            />
                            {
                                (errors && errors.selected_status) ? <span className="error show">{errors.selected_status}</span> : ''
                            }
                        </div>
                </div>
                <div className="">
                    <Button onClick={this.submitStatusChange} colclass="col-sm-12" btnClass="btn btn-primary" name="addleadbtn" id="addleadbtn" title={this.props.t('Listing.Status_Change_Popup.yes_change')} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

export default withTranslation('dealers')(connect(mapStateToProps)(ChangeStatusPopup));