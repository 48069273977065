import { statusChangeOptions, dealerStatusList } from "../../config/constant";
export const getStatusChangeOptions = (dealer_current_status) => {
    const status_options = Object.entries(statusChangeOptions)
            .filter(([key, value]) => key === dealer_current_status.toString()) // Ensure key conversion to string
            .map(([key, value]) => value);
    const options = status_options.length ? status_options[0] : [];
    return options;
}

export const getStatusByValue = (status) => {
    return dealerStatusList[status]
}

