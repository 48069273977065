import React, { Component } from "react";

//const carouselContainer = document.querySelector(".carousel-container");

// Carousel wrapper component
class ButtonGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_tab:1,
      btngroup:props.btngroup || []
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      btngroup: nextProps.btngroup,
    });
}
  render() {
    // let { buttongroup, className, key, id, label } = this.props;//need to remove all variables is not in use
    let { btngroup } = this.state;
    return (
      <div>
        <div role="group" className="btn-group mrg-b15">
          {btngroup && btngroup.map((btngroup, index) =>
            <button onClick={() => {this.setState({selected_tab:btngroup.id});this.props.changeTabs(btngroup.id)}} id={btngroup.id} type={btngroup.type} key={index} className={this.state.selected_tab == btngroup.id?btngroup.selectedClass: btngroup.class} >{btngroup.label} </button>
          )}
        </div>
      </div>
    );
  }
}
//render(<Carousel slides={carouselSlidesData} />, carouselContainer);

export default ButtonGroup;
// Render Carousel component

