import React, { Component } from 'react';
import InputField from '../../elements/InputField';
import Select from 'react-select';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import secureStorage from './../../../config/encrypt';
import OtpValidation from './otp-validate';
import { withTranslation } from 'react-i18next';
import MasterService from './../../../service/MasterService';
import {MOBILE_VALIDATION} from './../../../config/constant';
import {CONF_VAL} from './../../../config/constant';

var countryCodeListOption;
class OtpLogin extends Component {
    constructor(props){
        super(props);
        this.state = {
            showotpFrm:false,
            errors:{},
            // fields:{},//Need to remove duplicate key
            redirect: 0,
            countryCodeList:[],
            reloadCaptcha: 1,
            fields: { mobileCountryCode: "", captchaVal: "" , captchaId : ''},
        }
    }
    componentDidMount =  ()=>{
        this.getMobileCountryCode();
    }

    getMobileCountryCode = ()=>{
        MasterService.get('core/commonservice/master?master[]=country_code').then((response)=>{
            if(response.status === 200 && response.data.status === 200){
                let mobileCountryCode = response.data.data.country_code;

                countryCodeListOption = mobileCountryCode.map(function (countryCode) {
                    return { label: countryCode.value, value: countryCode.code };
                  })
                let fields = this.state.fields;
                fields['mobileCountryCode'] = countryCodeListOption.length ? countryCodeListOption[0]['value'] : '';
                this.setState({countryCodeList: countryCodeListOption,fields:fields});
            }
        });
    }


    handleChange = event => {
        let fields = {...this.state.fields};
        const errors = {...this.state.errors};
        let name='',value='';
        if(event.label){
            value = event.value
            name = 'mobileCountryCode'
        }else{
            name = event.target.name
            value =event.target.value;
        }
        fields[name] = value;
        if(value !== ''){
            delete errors[name];
        }else{
            errors[name] = 'Please enter '+name;
        }
        this.setState({ fields: fields, errors: errors },()=>{
            //console.log('fields',this.state.fields)
        });
    }

    handleChangeEvent = event => {     
        let fields = {...this.state.fields};
        const errors = {...this.state.errors};
        errors['captchaErr'] = '';
        errors['captchaErrorMessage'] = '';
        if(event && event.captchaData && event.captchaData.status==200){            
            fields["captchaVal"] = event.captchaVal;
            fields['captchaId'] = event.captchaData.data.value;
            if(event.captchaVal ==''){
                errors['captchaErr'] = 'Please enter valid captcha';
            }
        }else{
            fields[event.target.name] = event.target.value;
            if(event.target.value !== ''){
                delete errors[event.target.name];
            }else{
                errors[event.target.name] = 'Please enter '+event.target.name;
            }
        }                
        this.setState({ fields: fields,errors: errors });
    }

    
    submitOtpForm = (event)=>{
        var parentObj = this;
        let {captchaVal, captchaId, ...sendData} = this.state.fields;
        event.preventDefault();
        if(this.validForm()){
            MasterService.post('account/user/send_otp', sendData)
            .then(function (response) {
                if(response.status == 200 && response.data.status == 200){
                    // let responseData = response.data;//need to remove var not in use
                    parentObj.setState({showotpFrm:true});
                }else{
                    window.commonErrorToast(response.data.message);
                }
            }).catch(function (error) {
                window.commonErrorToast(error.message);
            });
        }
    }

    validForm = ()=>{
        let fields = {...this.state.fields};
        let errors = {...this.state.errors};
        let formIsValid = true;
        let mobileRule = MOBILE_VALIDATION;
        if (!fields["mobile"]) {
            errors['mobile'] = this.props.t('joinus.enter_mobile');
            formIsValid = false;
        }else if(!mobileRule.test(fields['mobile'])){
            errors['mobile'] = this.props.t('joinus.'+CONF_VAL.VALID_MOBILE_MSG);
            formIsValid = false;
        }        
        this.setState({errors: errors})
        return formIsValid;
    }

    validateOtpAndLogin = (event)=>{
        var parentObj = this;
        let errors = {...this.state.errors};
        event.preventDefault(); 
        if(this.validOtpForm()){
            let postData = Object.assign(this.state.fields);
            delete postData.mobileCountryCode;
            MasterService.post('account/user/login-otp', postData)
            .then(function (response) {
                if(response.status == 200 && response.data.status == 200){
                    let responseData = response.data;
                    secureStorage.setItem('loginUserInfo', responseData);
                    parentObj.props.updateStateIntoRedux('LOGIN_USER', responseData);
                    parentObj.setState({redirect: 1 })
                }else{
                    window.commonErrorToast(response.data.message);
                    if(response.data.message === 'Invalid Captcha' || response.data.message === 'Wrong Captcha'){
                        errors['captchaErr'] = response.data.message;
                        errors['captchaErrorMessage'] = response.data.message;
                    }else{
                        errors['otp'] = response.data.message;
                    }
                    parentObj.setState({errors: errors, reloadCaptcha: !(parentObj.state.reloadCaptcha), fields: {...parentObj.state.fields, captchaVal : ''} })
                }
            }).catch(function (error) {
                console.log(error);
            });
        }
    }

    validOtpForm = () => {
        let fields = { ...this.state.fields };
        let errors = { ...this.state.errors };
        let formIsValid = true;
        if (!fields["otp"]) {
            errors['otp'] = this.props.t('joinus.enter_otp');
            formIsValid = false;
        }
        if (errors["captchaErr"] || (!fields["captchaVal"])) {
            if (!fields["captchaVal"]) {
                errors['captchaErr'] = 'Captcha is required';
                errors['captchaErrorMessage'] = 'Captcha is required';
            } else if (errors["captchaErr"]) {
                errors['captchaErr'] = 'Please enter valid captcha';
                errors['captchaErrorMessage'] = 'Please enter valid captcha';
                this.setState({ reloadCaptcha: !(this.state.reloadCaptcha) });
            }
            formIsValid = false;
        }
        this.setState({ errors: errors })
        return formIsValid;
    }

    render() {
        if (this.state.redirect == 1) {
            return (<Redirect to={'/dashboard'} />);
        }
        const {errors,countryCodeList} = this.state;
        return (
            <div id="otp-forms">
                {this.state.showotpFrm === false?
                <form method="post" id="otp-form1" className=" text-left os-animation animated fadeInDown" data-os-animation="fadeInDown" data-os-animation-delay="0s">
                   <div className="phonewithcountry">
                        <div className="countrycode">
                            <Select
                                components={{ IndicatorSeparator:() => null }}
                                options={countryCodeList}
                                onChange={this.handleChange}
                                value={countryCodeList.filter(({ value }) => value === this.state.fields.mobileCountryCode)}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ value }) => value}
                            />
                        </div>
                        <div className="countrymobile">
                            <InputField
                                inputProps={{
                                    id: "mobile",
                                    type: "tel",
                                    name: "mobile",
                                    label:this.props.t('login.mobile'),
                                    dataerror: errors.mobile
                                }}
                                onChange={this.handleChange}
                            />
                        </div>
                   </div>                       
                    <div className="form-group" id="showbutton">
                        <button type="submit" className="btn btn-primary btn-lg btn-block" id="send-otp" onClick={this.submitOtpForm}>{this.props.t('login.get_otp')}</button>
                    </div>
                </form>
                :
                <OtpValidation errors={this.state.errors} onClick={this.validateOtpAndLogin} onChange={this.handleChangeEvent} reloadCaptcha={this.state.reloadCaptcha} />
                }
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        user_loggedin: state.userLoggedIn
    };
 }

 const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({type:actionType, componentState: stateData})
    }
 }
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)( OtpLogin));
