import React, { Component } from "react";
import Button from '../../elements/Button'
import MasterService from './../../../service/MasterService';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import {BUMP_UP_EXPIRY_HOURS} from './../../../config/constant';
import moment from 'moment';
let date_format = "YYYY-MM-DD hh:mm a"

class MakeBumpUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_bump_enable: props.is_bump_enable,
            carId: props.carId,
            carOne: props.carOne,
            currentIndex:props.currentIndex
        }
    }

    bumpup = () => { 
        var thisObj = this;
        let postObj = {car_id:this.state.carId};
        let updateJsonRow={};
        if(this.state.is_bump_enable=='true'){
            updateJsonRow.is_bump_enable='1';
            let dt = new Date(); ;
            dt.setHours( dt.getHours() + BUMP_UP_EXPIRY_HOURS );
            updateJsonRow.bump_up_expiry_date = dt;  

        }else{
            updateJsonRow.is_bump_enable = 0;
            updateJsonRow.bump_up_expiry_date = null;  
        }
        // let carId = this.state.carId;//need to remove var not in use
        MasterService.post('inventory/inventory/bump_up_stock',postObj)
            .then(function (response) {
                if (response.data.status == 200){
                    toast.success(response.data.message);
                    if (typeof thisObj.props.updateStockData === 'function') {
                        thisObj.props.updateStockData(updateJsonRow, thisObj.state.currentIndex);
                      }
                    thisObj.props.modalClose();
                    thisObj.props.getbumpUpStatus();
                }
                else
                {
                    toast.error(response.data.message);
                    thisObj.props.modalClose();
                }
            })
            .catch(function (response) {
            });
        }

    render() {
        let last_bump_up_date = this.state.carOne.bump_up == "1" && this.state.carOne.bump_up_date;
        return (
            <div>
                <p>{this.props.t('listInventory.bump_up_modal_pop_body_msg')}</p>
                {last_bump_up_date && <small><i>Note: Last Bump Up date on this car: {moment(last_bump_up_date).format(date_format)}</i></small>}
                <div className="col-sm-6 col-md-12 form-group text-right">
                    <Button type="button" onClick={this.bumpup} colclass="col-sm-12" btnClass="btn btn-primary" name="addleadbtn" id="addleadbtn" title={this.props.t('listInventory.bump_up')} />
                </div>
            </div>
        );
    }
}

export default withTranslation('inventory')(MakeBumpUp);