import React, { Component } from "react";
import Button from '../../elements/Button'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ModalPopup from "../../elements/Popup";

class MappedUserPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openPopup: false,
        };
    }

    handleOpenPopup = () => {
        this.setState({ openPopup: true })
    }

    handleClosePopup = () => {
        this.setState({ openPopup: false })
    }

    render() {
        console.log("this.props", this.props)
        let { mapped_user } = this.props;
        return (
            <div>
                <div className="btn nowrap">
                    <div onClick={this.handleOpenPopup}>Mapped Users</div>
                    {this.state.openPopup == false ? null :
                        <ModalPopup id="mapped_users" className="modal mapped_users_outer" title={"Mapped Users"} modalClose={this.handleClosePopup}>
                            <ul>
                                {mapped_user && mapped_user.length > 0 && mapped_user.map((element) => (
                                    <li>
                                        <label>{element.name}</label>
                                        <label>{" - " + element.role}</label>
                                        <label>{" - " + element.id}</label>
                                    </li>
                                ))}
                            </ul>
                        </ModalPopup>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

export default withTranslation('dealers')(connect(mapStateToProps)(MappedUserPopup));