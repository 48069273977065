import React, { Component } from 'react';
import InputField from '../elements/Input';
import Select from 'react-select';
import Button from '../elements/Button';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { MOBILE_VALIDATION, COUNTRY_CODE } from '../../config/constant';
import secureStorage from '../../config/encrypt';
import MasterService from '../../service/MasterService';
import ModalPopup from '../elements/Popup';
import { Link  } from 'react-router-dom';
import { DealerService } from '../../service';

class BasicDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: props.basic_details ? false : true,
      basic_details: props.basic_details || {},
      reset_password: false,
      errors: {},
      redirect:false,
      getLanguagelist: props.getLanguagelist,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      getLanguagelist: nextProps.getLanguagelist,
      loading: nextProps.loading,
      basic_details: {
        ...nextProps.basic_details,
      },
    });
  }

  validForm = () => {
    let fields = this.state.basic_details;
    let formIsValid = true;
    let errors = { ...this.state.errors };

    if(Object.keys(errors).length > 0){
      formIsValid = false;
    }

    if (!fields['name']) {
      errors['name'] = this.props.t('salessupportuserDetails.Basic_Employee_Details.name_required');
      formIsValid = false;
    }

    if (!fields['mobile'] || fields['mobile'].length < 9) {
      errors['mobile'] = this.props.t('salessupportuserDetails.Basic_Employee_Details.mobile_required');
      formIsValid = false;
    }

    if (!fields['email']) {
      errors['email'] = this.props.t('salessupportuserDetails.Basic_Employee_Details.email_required');
      formIsValid = false;
    }

    if (!fields['role_id']) {
      errors['role_id'] = this.props.t('salessupportuserDetails.Basic_Employee_Details.user_type_required');
      formIsValid = false;
    }

    this.setState({ errors: errors });
    return formIsValid;
  };

  saveBasicDetail = (event) => {
    this.setState({ loading: true });
    event.preventDefault();
    if (this.validForm()) {
      if (this.state.basic_details) {
        let data = {
          ...this.state.basic_details,
        };
        delete data.id
        let METHOD = 'post';
        if (data.sales_id_hash) {
          METHOD = 'put';
        }

    DealerService.saveSalesBasicinfo(data, METHOD)
          .then((response) => {
            this.setState({ loading: false});
            // eslint-disable-next-line eqeqeq
            if (response.data.status == 200) {
              this.props.redirect_basic("/admin/sales-support-users")
              toast.success(response.data.message);
            }else{
              toast.error(response.data.message);
            }
          })
          .catch((error) => console.log(error));
      } else {
        this.setState({ loading: false });
        toast.error(this.props.t('salessupportuserDetails.Basic_Employee_Details.validation_texts.fill_required_fields'));
      }
    } else {
      this.setState({ loading: false });
    }
  };

  handleLeadOptionChange = (sname, oValue) => {
    let basic_details = this.state.basic_details;
    const errors = this.state.errors;
    basic_details[sname] = oValue.id;
    basic_details["role_name"] = oValue.name;
    delete errors[sname];
    this.setState({
      basic_details,
      errors,
    });
  };

  handleInputChange = (name, event) => {
    let basic_details = this.state.basic_details;
    const errors = this.state.errors;

    // eslint-disable-next-line eqeqeq
    if (event.target.name == 'name' || event.target.name == 'email' || event.target.name == 'mobile' || event.target.name == 'password') {
      basic_details[event.target.name] = event.target.value;
    }

    var nameRex = new RegExp("^[a-zA-Z0-9\\-\\s]+$");
    // eslint-disable-next-line eqeqeq
    if (event.target.value != '') {
      // eslint-disable-next-line eqeqeq
      if (event.target.name == 'email') {
        // eslint-disable-next-line no-useless-escape
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value)) {
          if (
            event.target.value.indexOf('@oto.com', event.target.value.length - '@oto.com'.length) !== -1 ||
            event.target.value.indexOf('@cardekho.com', event.target.value.length - '@cardekho.com'.length) !== -1 ||
            event.target.value.indexOf('@triivco.com', event.target.value.length - '@triivco.com'.length) !== -1 ||
            event.target.value.indexOf('@otosupport.com', event.target.value.length - '@otosupport.com'.length) !== -1 ||
            event.target.value.indexOf('@girnarsoft.com', event.target.value.length - '@girnarsoft.com'.length) !== -1 ||
            event.target.value.indexOf('@girnarsoft.co.in', event.target.value.length - '@girnarsoft.co.in'.length) !== -1
          ) {
            delete errors[event.target.name];
        }else {
          errors[event.target.name] = this.props.t('salessupportuserDetails.Basic_Employee_Details.email_required');
        }
        } else {
          errors[event.target.name] = this.props.t('salessupportuserDetails.Basic_Employee_Details.email_required');
        }
        // eslint-disable-next-line eqeqeq
      } else if (event.target.name == 'email') {
        delete errors[event.target.name];
        // eslint-disable-next-line eqeqeq
      } else if (event.target.name == 'name') {
        if (!(nameRex.test(event.target.value))) {
          errors[event.target.name] = this.props.t('salessupportuserDetails.Basic_Employee_Details.valid_name_required');
        } else {
          delete errors[event.target.name];
        }
        // eslint-disable-next-line eqeqeq
      } else if (event.target.name == 'mobile') {
        if (!MOBILE_VALIDATION.test(event.target.value)) {
          errors[event.target.name] = this.props.t('salessupportuserDetails.Basic_Details_master.validation_texts.enter_valid_phone_' + COUNTRY_CODE);
        } else {
          delete errors[event.target.name];
        }
      }
    } 
    this.setState({ basic_details, errors });
  };

  showConfirmModal = () => {
    this.setState({ reset_password: true });
  };
  closeConfirmModal = () => {
    this.setState({ reset_password: false });
  };

  handleReset = () => {
    this.setState({ loading: true });
    this.setState({ reset_password: false });
    MasterService.post(`/dealer/dealer/salesuser_reset_password`, { id: this.state.basic_details.id })
      .then((response) => {
        if (response && response.data && response.data.status === 200) {
          toast.success(response.data.message);
          this.setState({ loading: false, reset_password: false });
        } else {
          toast.error(response.data.message);
          this.setState({ loading: false, reset_password: false });
        }
      })
      .catch((error) => {
        toast.error(error);
        this.setState({ loading: false, reset_password: false });
      });
  };

  render() {
    let basicinfo = this.state.basic_details;
    let { errors, getLanguagelist } = this.state;
    let role_access = [];
    if (secureStorage.getItem('loginUserInfo')) {
      let loginResponse = secureStorage.getItem('loginUserInfo');
      role_access =
        loginResponse &&
        loginResponse.data &&
        loginResponse.data.user_data &&
        loginResponse.data.user_data.role_data &&
        loginResponse.data.user_data.role_data.length > 0
          ? loginResponse.data.user_data.role_data
          : [];
    }
    return (
      <div>
        <form
          className={this.state.loading ? 'text-left os-animation animated fadeInDown loading' : 'text-left os-animation animated fadeInDown'}
          data-os-animation="fadeInDown"
          data-os-animation-delay="0s"
          method="post"
          id="basicdetail_form"
          onSubmit={this.saveBasicDetail}
        >
          <div className="card">
            <div className="card-heading">
              <h2 className="card-title">{this.props.t('salessupportuserDetails.Basic_Details_master.Basic_Details')}</h2>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-4">
                  <InputField
                    maxLength="80"
                    type="text"
                    error={errors.name ? errors.name : ''}
                    name="name"
                    id="name"
                    autocomplete="off"
                    placeholder={this.props.t('salessupportuserDetails.Basic_Employee_Details.Name')}
                    value={basicinfo.name ? basicinfo.name : ''}
                    label={this.props.t('salessupportuserDetails.Basic_Employee_Details.Name')}
                    onChange={this.handleInputChange.bind(this, 'name')}
                    required={true}
                  />
                </div>
                <div className="col-sm-4">
                  <InputField
                    type="text"
                    maxLength="12"
                    error={errors.mobile ? errors.mobile : ''}
                    name="mobile"
                    id="mobile"
                    autocomplete="off"
                    placeholder={this.props.t('salessupportuserDetails.Basic_Employee_Details.Mobile')}
                    value={basicinfo.mobile ? basicinfo.mobile : ''}
                    label={this.props.t('salessupportuserDetails.Basic_Employee_Details.Mobile')}
                    onChange={this.handleInputChange.bind(this, 'mobile')}
                    required={true}
                  />
                </div>
                <div className="col-sm-4">
                  <InputField
                    type="text"
                    maxLength="150"
                    error={errors.email ? errors.email : ''}
                    name="email"
                    id="email"
                    readOnly={basicinfo && basicinfo.id ? true : false}
                    autocomplete="off"
                    placeholder={this.props.t('salessupportuserDetails.Basic_Employee_Details.Email')}
                    value={this.state.basic_details.email ? this.state.basic_details.email : ''}
                    label={this.props.t('salessupportuserDetails.Basic_Employee_Details.Email')}
                    onChange={this.handleInputChange.bind(this, 'email')}
                    required={true}
                  />
                </div>
              </div>
              <div className="row">
                {getLanguagelist && getLanguagelist.length > 1 ? (
                  <div className="form-field col-sm-4">
                    <label>
                      {this.props.t('salessupportuserDetails.Basic_Employee_Details.Role')}
                      <span className="required" style={{ color: 'red' }}>
                        *
                      </span>
                    </label>
                    <Select
                      components={{ IndicatorSeparator:() => null }}
                      className={errors.role_id ? 'field-error' : 'form-field'}
                      id="role_id"
                      isDisabled={role_access.includes('sales_user_role_change') ? false :true}
                      options={getLanguagelist}
                      onChange={this.handleLeadOptionChange.bind(this, 'role_id')}
                      name="role_id"
                      placeholder={this.props.t('salessupportuserDetails.Basic_Employee_Details.Select_Role')}
                      value={getLanguagelist.filter(({ id }) => id === basicinfo.role_id)}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                    />
                    <span className="error show">{errors.role_id ? errors.role_id : ''}</span>
                  </div>
                ) : (
                  ''
                )}

                {basicinfo && basicinfo.id ? (
                  <div className="col-sm-4">
                    <InputField
                      type="password"
                      readOnly={basicinfo && basicinfo.id ? true : false}
                      error={errors.password ? errors.password : ''}
                      name="password"
                      id="password"
                      placeholder="Password"
                      value="**********"
                      label="Password"
                      onChange={this.handleInputChange.bind(this, 'password')}
                      required={true}
                    />
                  </div>):(
                  ''
                )}

                {basicinfo && basicinfo.id ? (
                  <div className="col-sm-4 mrg-t25">
                    <Link
                      onClick={(e) => {
                        this.showConfirmModal();
                      }}
                      className="btn btn-link"
                    >
                      {this.props.t('salessupportuserDetails.Basic_Employee_Details.reset_pass')}
                    </Link>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="card-footer text-center">
              {role_access.includes('sales_user_save') ? (
                <Button btnClass="btn btn-primary mrg-r30" title={this.props.t('salessupportuserDetails.Basic_Details_master.save')} type="submit" />
              ) : (
                ''
              )}
            </div>
          </div>
        </form>
        {this.state.reset_password === false ? null : (
          <ModalPopup
            className="modal"
            title={this.props.t('salessupportuserDetails.Basic_Employee_Details.CONFIRM')}
            modalClose={this.closeConfirmModal}
          >
            <div>
              <p>{this.props.t('salessupportuserDetails.Basic_Employee_Details.confirm_msg')} </p>
              <div className="col-sm-6 col-md-12 form-group text-right">
                <Button
                  type="button"
                  colclass="col-sm-12"
                  onClick={this.closeConfirmModal}
                  btnClass="btn btn-primary"
                  name="closeconfirm"
                  id="closeconfirm"
                  title={this.props.t('salessupportuserDetails.Basic_Employee_Details.no')}
                />
                &nbsp;&nbsp;
                <Button
                  type="button"
                  colclass="col-sm-12"
                  onClick={() => {
                    this.handleReset(this.state.basic_details);
                  }}
                  btnClass="btn btn-success"
                  name="ok"
                  id="ok"
                  title={this.props.t('salessupportuserDetails.Basic_Employee_Details.yes')}
                />
              </div>
            </div>
          </ModalPopup>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation('sales_support_users')(connect(mapStateToProps, mapDispatchToProps)(BasicDetails));
