import React, { Component } from "react";
// import InputField from './../../elements/InputField'//need to remove
import Button from '../../elements/Button'
// import DateFormate from 'dateformat';//need to remove
// import MasterService from './../../../service/MasterService';//need to remove
import { withTranslation } from 'react-i18next';
// import { toast } from 'react-toastify';//need to remove
import { connect } from 'react-redux';
import Select from 'react-select';
import { Reason_For_Inactivate } from "../../../config/constant";

class InactiveReasonPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inactiveReasonUpdateData: this.props.inactiveReasonUpdateData,
            currentIndex: this.props.currentIndex,
            current_org_name: this.props.current_org_name,
            errors: {},
            inactive_reason: "",
            inactive_reason_comment: ""
        };
    }

    componentDidMount = () => {
        this.loadAllComponentData();
    }

    loadAllComponentData = async () => {

    }

    submitInactiveReasonForm = async (event) => {
        event.preventDefault();
        var thisObj = this;
        if (this.validFormData()) {
            if (typeof thisObj.props.handleStatusChange === 'function') {
                let { currentIndex, inactiveReasonUpdateData, inactive_reason, inactive_reason_comment } = thisObj.state
                let { dealer_id_hash, status, checked, target } = inactiveReasonUpdateData
                thisObj.props.handleStatusChange(currentIndex, dealer_id_hash, status, checked, target, inactive_reason, inactive_reason_comment.trim());
            }
            thisObj.props.modalClose();
        }
    }
    validFormData = () => {
        let errors = {};
        let formIsValid = true;
        if (!this.state.inactive_reason) {
            errors['inactive_reason'] = this.props.t('Listing.Inactivate_Reason_Popup.reason_required');
            formIsValid = false;
        } else if (this.state.inactive_reason == 'others' && this.state.inactive_reason_comment.trim() == '') {
            errors['inactive_reason_comment'] = this.props.t('Listing.Inactivate_Reason_Popup.reason_required');
            formIsValid = false;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleReasonChange = (sname, ovalue) => {
        let errors = {};
        errors['inactive_reason'] = ""
        errors['inactive_reason_comment'] = ""
        let inactive_reason = this.state.inactive_reason;
        inactive_reason = (ovalue.value) != null ? ovalue.value : ovalue.target.value;
        this.setState({ inactive_reason, errors })
    }

    handleChangetext = (event) => {
        let errors = {};
        errors['inactive_reason'] = ""
        errors['inactive_reason_comment'] = ""
        let fieldRequired = event.target.getAttribute('validationreq');
        let inactive_reason_comment = this.state.inactive_reason_comment;
        
        if (fieldRequired === 'yes') {
            if (event.target.value === '') {
                errors[event.target.name] = this.props.t('Listing.Inactivate_Reason_Popup.inactive_reason_comment_required');
            } else {
                delete errors[event.target.name];
            }
        }
        inactive_reason_comment = event.target.value;
        this.setState({ inactive_reason_comment, errors }, () => { });
    }

    render() {
        // const { formData, errors, current_org_name } = this.state;//need to remove
        const { errors } = this.state;
        let css = '';
        if (errors) {
            css = `.form-error {display:block}`;
        }
        return (
            <div>
                <style type="text/css">
                    {css}
                </style>
                <div className="row">
                    <div className="">
                        <div className="col-sm-6 col-md-12 form-group">
                            <Select
                                components={{ IndicatorSeparator:() => null }}
                                id="reason_for_inactivate"
                                options={Reason_For_Inactivate}
                                value={Reason_For_Inactivate.filter(({ value }) => value === this.state.inactive_reason)}
                                onChange={this.handleReasonChange.bind(this, 'inactive_reason')}
                                name="inactive_reason"
                                placeholder={this.props.t('Listing.Inactivate_Reason_Popup.Select_message')}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ value }) => value}
                            />
                            {
                                (errors && errors.inactive_reason) ? <span className="error show">{errors.inactive_reason}</span> : ''
                            }
                        </div>
                        {this.state.inactive_reason == 'others' &&
                            <div className="col-sm-6 col-md-12 form-group">
                                <textarea placeholder='Inactive Reason Comment' onChange={this.handleChangetext} rows='4' className="form-control" name="inactive_reason_comment" value={this.state.inactive_reason_comment}></textarea>
                                {
                                    (errors && errors.inactive_reason_comment) ? <span className="error show">{errors.inactive_reason_comment}</span> : ''
                                }
                            </div>
                        }
                    </div>

                    <div className="col-sm-6 col-md-12 form-group text-right btn-save-close">
                        <Button onClick={this.props.modalClose} colclass="col-sm-12" btnClass="btn btn-default" type="reset" name="addleadbtn" id="cancel" title={this.props.t('Listing.Inactivate_Reason_Popup.Cancel')} />
                        <Button onClick={this.submitInactiveReasonForm} colclass="col-sm-12" btnClass="btn btn-primary" name="addleadbtn" id="addleadbtn" title={this.props.t('Listing.Inactivate_Reason_Popup.Save')} />
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

export default withTranslation('dealers')(connect(mapStateToProps)(InactiveReasonPopup));