import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Brandlogo from "../elements/Brandlogo";
import Navbar from "./Navbar";
import secureStorage from './../../config/encrypt';
import { connect } from 'react-redux';
import MasterService from './../../service/MasterService';
class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      redirect: 0,
      loginuser: '',
      menuOpen:false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  logout = () => {
    this.logoutUser();
    secureStorage.removeItem('loginUserInfo');
    this.setState({ redirect: 1 }, () => {this.props.updateStateIntoRedux('LOGOUT_USER', '')} );
  }
  logoutUser() {
    MasterService.post('account/user/logout', {})
      .then((res) => {
        console.log("logout");
        window.location.reload();
      })
      .catch(function (err) {
        console.error("searchError", err)
      });
  }
  componentDidMount() {

  }
  handleMenuClick() {
    this.setState({menuOpen:!this.state.menuOpen});
    document.body.classList.toggle('body-hidden');
  }
  
  handleLinkClick() {
    this.setState({menuOpen: false});
    
  }

  render() {
    const styles= 
      {

      }
    if (this.state.redirect == 1) {
      return (<Redirect to={'/login'} />);
    }
    return (
      <header className="mainHeader header-fixed clearfix">
        {/* <span className="ic-menu hamburger-icon" className={ this.state.menuOpen ? "ic-clearclose hamburger-icon" : "ic-menu hamburger-icon" } open={this.state.menuOpen} onClick={()=>this.handleMenuClick()}></span> need to remove*/}
        <span className={ this.state.menuOpen ? "ic-clearclose hamburger-icon" : "ic-menu hamburger-icon" } open={this.state.menuOpen} onClick={()=>this.handleMenuClick()}></span>
        <Brandlogo />
        <Navbar logoutUser = {this.logout}  navClass={ this.state.menuOpen ? "open" : "" } style={styles.container}/>
        
        <div className={ this.state.menuOpen ? "modal-backdrop" : "modal-backdrop hide" } onClick={()=>this.handleMenuClick()}></div>
      </header>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
      updateStateIntoRedux: (actionType, stateData) => dispatch({type:actionType, componentState: stateData})
  }
}
export default connect(null, mapDispatchToProps)(Header);