import React, { Component } from "react";
import Button from '../../elements/Button'
import MasterService from './../../../service/MasterService';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

class MakeBursa extends Component {
    constructor(props) {
        console.log("props---",props);
        super(props);
        this.state = {
            carId: props.carId,
            disable_bursa: false,
            bursaVal:props.bursaVal,
            maxlimit: false,
            currentIndex:props.currentIndex,
            errors: {}
        }
    }

    markInventoryBursa = () => {  
            var thisObj = this;
            thisObj.setState({
                disable_bursa: true
            });
            var postObj = { bursaVal: this.state.bursaVal, stock_used_car_id: this.state.carId  };
            let updateJsonRow={};
            if(this.state.bursaVal=='true'){
                updateJsonRow.is_bursa=1;
            }else{
                updateJsonRow.is_bursa=0;
            }
    
            MasterService.post('inventory/inventory/makeCarPremium', postObj)
                .then(function (response) {
                   updateJsonRow.is_bursa = (postObj.bursaVal=="false") ?  '0' : '1';
                    thisObj.flag = false;
                    if (response.data.status == 200) {
                        toast.success(response.data.message);
                        if (typeof thisObj.props.updateStockData === 'function') {
                            thisObj.props.updateStockData(updateJsonRow, thisObj.state.currentIndex);
                          }
                        thisObj.setState({
                            disable_bursa: false
                        });
                        thisObj.props.modalClose();
                        thisObj.props.getbumpUpStatus();
                    }else{
                        thisObj.setState({
                            disable_bursa: false
                        });
                        toast.error(response.data.message);
                        thisObj.props.modalClose();
                    }
                })
                .catch(function (response) {
                });
        
    }

    render() {
        let maxBursamsg = [];
        // let errors = this.state.errors;//need to remove var not in use
        if (this.state.bursaVal == 'true') {
            maxBursamsg.push(<p key='1'>{this.props.t('listInventory.mark_bursa_msg')}</p>);
        } else {
            maxBursamsg.push(<p key='2'>{this.props.t('listInventory.mark_disable_bursa_msg')}</p>);  
        }
      
        // if(this.state.maxlimit==true){
        //     maxBursamsg= <div className="alert alert-danger">
        //     <p>
        //         <strong className="text-primary">{this.props.t('listInventory.classified_exceed_msg_1')}</strong>
        //     </p>
        //     <p>{this.props.t('listInventory.classified_exceed_msg_2')}</p>
        // </div>
        // }
        return (
            <div>
                {maxBursamsg}
                <div className="col-sm-6 col-md-12 form-group text-right">
                <Button type="button" disabled = {this.state.disable_bursa} onClick={this.markInventoryBursa} colclass="col-sm-12" btnClass="btn btn-primary" name="addleadbtn" id="addleadbtn" title="Save" />
            </div> 
            </div>
        );
    }
}

export default withTranslation('inventory')(MakeBursa);