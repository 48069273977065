import React, { Component } from "react";

class ViewPDF extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pdf_src: this.props.pdf_url,
        };
    }

    render() {
        return (
            <div>
                <div>
                    <object data={this.state.pdf_src} type="application/pdf" width="100%" height="450px">
                        <p>Alternative link <a href={this.state.pdf_src}>to the PDF!</a></p>
                    </object>
                </div>
            </div>
        );
    }
}

export default ViewPDF;
