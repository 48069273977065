// import React, { Component } from 'react';
// import Select from 'react-select';
// import InputField from '../../elements/Input';
// import CheckBox from '../../elements/CheckBox';
// import Radiobox from '../../elements/Radiobox';
// import MmvDropDown from './../../enquiry/component/MmvDropDown';


// const month = [
//     { value: 'Jan', label: 'Jan' },
//     { value: 'Fab', label: 'Fab' },
//     { value: 'Mar', label: 'Mar' },
//     { value: 'Apr', label: 'Apr' },
//     { value: 'May', label: 'May' },
//     { value: 'Jun', label: 'Jun' },
//     { value: 'Jul', label: 'Jul' },
//     { value: 'Aug', label: 'Aug' },
//     { value: 'Sep', label: 'Sep' },
//     { value: 'Oct', label: 'Oct' },
//     { value: 'Nov', label: 'Nov' },
//     { value: 'Dec', label: 'Dec' }

// ];

// const year = [
//     { value: '2019', label: '2019' },
//     { value: '2018', label: '2018' },
//     { value: '2017', label: '2017' },
//     { value: '2016', label: '2016' },
//     { value: '2015', label: '2015' },
//     { value: '2014', label: '2014' },
//     { value: '2013', label: '2013' },
//     { value: '2012', label: '2012' }
// ];


// const make = [
//     { value: 'maruti', label: 'Maruti' },
//     { value: 'hyundai', label: 'Hyundai' },
//     { value: 'honda', label: 'Honda' },
//     { value: 'tata', label: 'Tata' },
//     { value: 'mahindra', label: 'Mahindra' },
//     { value: 'renault', label: 'Renault' },
//     { value: 'ford', label: 'Ford' },
//     { value: 'nissan', label: 'Nissan' }
// ];

// const model = [
//     { value: 'maruti', label: 'Maruti' },
//     { value: 'hyundai', label: 'Hyundai' },
//     { value: 'honda', label: 'Honda' },
//     { value: 'tata', label: 'Tata' },
//     { value: 'mahindra', label: 'Mahindra' },
//     { value: 'renault', label: 'Renault' }

// ];

// const varient = [
//     { value: 'maruti', label: 'Maruti' },
//     { value: 'hyundai', label: 'Hyundai' },
//     { value: 'honda', label: 'Honda' },
//     { value: 'tata', label: 'Tata' },
//     { value: 'mahindra', label: 'Mahindra' },
//     { value: 'renault', label: 'Renault' },
//     { value: 'ford', label: 'Ford' }
// ];

// const color = [
//     { value: 'maruti', label: 'Maruti' },
//     { value: 'hyundai', label: 'Hyundai' },
//     { value: 'honda', label: 'Honda' },
//     { value: 'tata', label: 'Tata' },
//     { value: 'mahindra', label: 'Mahindra' },
//     { value: 'renault', label: 'Renault' },
//     { value: 'ford', label: 'Ford' }
// ];
// const pricemin = [
//     { value: '50000', label: '50,000' },
//     { value: '100000', label: '1 Lakh' },
//     { value: '200000', label: '2 Lakh' },
//     { value: '300000', label: '3 Lakh' },
//     { value: '400000', label: '4 Lakh' },
//     { value: '500000', label: '5 Lakh' },
//     { value: '600000', label: '6 Lakh' }
// ];
// const pricemax = [
//     { value: '50000', label: '50,000' },
//     { value: '100000', label: '1 Lakh' },
//     { value: '200000', label: '2 Lakh' },
//     { value: '300000', label: '3 Lakh' },
//     { value: '400000', label: '4 Lakh' },
//     { value: '500000', label: '5 Lakh' },
//     { value: '5000000', label: '50 Lakh' }
// ];

// const kms = [
//     { value: '10000', label: '10000' },
//     { value: '20000', label: '20000' },
//     { value: '30000', label: '30000' },
//     { value: '40000', label: '40000' },
//     { value: '50000', label: '50000' },
//     { value: '60000', label: '60000' },
//     { value: '70000', label: '70000' }
// ];
// const kmsmax = [
//     { value: '10000', label: '10000' },
//     { value: '20000', label: '20000' },
//     { value: '30000', label: '30000' },
//     { value: '40000', label: '40000' },
//     { value: '50000', label: '50000' },
//     { value: '60000', label: '60000' },
//     { value: '70000', label: '70000' }
// ];
// const owner = [
//     { value: 'maruti', label: 'Maruti' },
//     { value: 'hyundai', label: 'Hyundai' },
//     { value: 'honda', label: 'Honda' },
//     { value: 'tata', label: 'Tata' },
//     { value: 'mahindra', label: 'Mahindra' },
//     { value: 'renault', label: 'Renault' },
//     { value: 'ford', label: 'Ford' }
// ];
// const certification = [
//     { value: '0', label: 'None' },
//     { value: '4', label: 'Carnation Certified' },
//     { value: '13', label: 'Audi Approved:plus' },
//     { value: '13', label: 'Car X Change' }
// ];
// const fuel_type = [
//     { value: "0", label: 'Petrol' },
//     { value: "1", label: 'Diesel' },

// ];
// class SearchStock extends Component {
//     constructor(props) {
//         super(props);
//         this.handleSelect = this.handleSelect.bind(this);
//         this.handleChangetext = this.handleChangetext.bind(this);
//         this.state = {

//             car_make: '',
//             car_model: ''
//         };
//     }

//     handleSelect(key) {
//         this.setState({ key });
//     }

//     handleChange = (sname, ovalue) => {
//         this.setState({
//             [sname]: ovalue.value
//         });
//     }

//     handleChangetext = (event) => {
//         this.setState({
//             [event.target.name]: event.target.value
//         });
//     }

//     handleMmvChange = (kname, selvalue) => {
//         this.setState({
//             [kname]: selvalue.id
//         });
//     }

//     componentWillMount() {
//         if (sessionStorage.getItem('userData')) {
//             this.setState({ redirect: true })
//         }
//     }

//     render() {
//         return (
//             <div className="search-wrap">
//                 <div className="row">
//                     <div className="col-sm-6 col-md-2">
//                         <label>Search</label>
//                         <InputField
//                             id="car_id_reg_no"
//                             type="tel"
//                             placeholder="Search by reg no."
//                             name="car_id_reg_no"
//                             autocompleate="off"
//                             value=""
//                             label="Search by reg no."
//                         />
//                     </div>



//                     <MmvDropDown handleOptionChange={this.handleMmvChange} />

//                     <div className="col-sm-6 col-md-2 form-group">
//                         <label>Select Price range</label>
//                         <div className="row">
//                             <div className="col-xs-6">
//                                 <Select
//                                     components={{ IndicatorSeparator:() => null }}
//                                     id="select_price_min_list"
//                                     value={this.state.pricemin}
//                                     onChange={this.handleChange.bind(this, 'pricemin')}
//                                     options={pricemin}
//                                     name="select_price_min_list"
//                                     placeholder="Min"
//                                     value={pricemin.filter(({ value }) => value === this.state.pricemin)}
//                                     getOptionLabel={({ label }) => label}
//                                     getOptionValue={({ value }) => value}
//                                 />
//                             </div>
//                             <div className="col-xs-6">
//                                 <Select
//                                     components={{ IndicatorSeparator:() => null }}
//                                     id="select_price_max_list"
//                                     value={this.state.pricemax}
//                                     onChange={this.handleChange.bind(this, 'pricemax')}
//                                     options={pricemax}
//                                     name="select_price_max_list"
//                                     placeholder="Max"
//                                     value={pricemax.filter(({ value }) => value === this.state.pricemax)}
//                                     getOptionLabel={({ label }) => label}
//                                     getOptionValue={({ value }) => value}
//                                 />
//                             </div>
//                         </div>
//                     </div>


//                     <div className="col-sm-6 col-md-2 form-group">
//                         <label>Year Range</label>
//                         <div className="row">
//                             <div className="col-xs-6">
//                                 <Select
//                                     components={{ IndicatorSeparator:() => null }}
//                                     id="select_myear_from_list"
//                                     value={this.state.year}
//                                     onChange={this.handleChange.bind(this, 'year')}
//                                     options={year}
//                                     name="select_myear_from_list"
//                                     placeholder="Min"
//                                     value={year.filter(({ value }) => value === this.state.year)}
//                                     getOptionLabel={({ label }) => label}
//                                     getOptionValue={({ value }) => value}
//                                 />
//                             </div>
//                             <div className="col-xs-6">
//                                 <Select
//                                     components={{ IndicatorSeparator:() => null }}
//                                     id="select_myear_to_list"
//                                     value={this.state.year}
//                                     onChange={this.handleChange.bind(this, 'year')}
//                                     options={year}
//                                     name="select_myear_to_list"
//                                     placeholder="Max"
//                                     value={year.filter(({ value }) => value === this.state.year)}
//                                     getOptionLabel={({ label }) => label}
//                                     getOptionValue={({ value }) => value}
//                                 />
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-sm-6 col-md-2 form-group">
//                         <label>Kilometers Range</label>
//                         <div className="row">
//                             <div className="col-xs-6">
//                                 <Select
//                                     components={{ IndicatorSeparator:() => null }}
//                                     id="select_km_max_list"
//                                     value={this.state.kms}
//                                     onChange={this.handleChange.bind(this, 'kms')}
//                                     options={kms}
//                                     name="select_km_max_list"
//                                     placeholder="From"
//                                     value={kms.filter(({ value }) => value === this.state.kms)}
//                                     getOptionLabel={({ label }) => label}
//                                     getOptionValue={({ value }) => value}
//                                 />
//                             </div>
//                             <div className="col-xs-6">
//                                 <Select
//                                     components={{ IndicatorSeparator:() => null }}
//                                     id="select_km_min_list"
//                                     value={this.state.kmsmax}
//                                     onChange={this.handleChange.bind(this, 'kmsmax')}
//                                     options={kmsmax}
//                                     name="select_km_min_list"
//                                     placeholder="To"
//                                     value={kmsmax.filter(({ value }) => value === this.state.kmsmax)}
//                                     getOptionLabel={({ label }) => label}
//                                     getOptionValue={({ value }) => value}
//                                 />
//                             </div>
//                         </div>
//                     </div>

//                     <div className="col-sm-6 col-md-2 form-group hide">
//                         <label>Fuel Type</label>
//                         <div>
//                             <Select
//                                 components={{ IndicatorSeparator:() => null }}
//                                 id="select_fuel_type_list"
//                                 value={this.state.fuel_type}
//                                 onChange={this.handleChange.bind(this, 'fuel_type')}
//                                 options={fuel_type}
//                                 name="select_fuel_type_list"
//                                 placeholder="Fuel Type"
//                                 value={fuel_type.filter(({ value }) => value === this.state.fuel_type)}
//                                 getOptionLabel={({ label }) => label}
//                                 getOptionValue={({ value }) => value}
//                             />
//                         </div>
//                     </div>
//                     <div className="col-sm-6 col-md-2 form-group hide">
//                         <label>Age Of Inventory</label>
//                         <div>
//                             <Select
//                                 components={{ IndicatorSeparator:() => null }}
//                                 id="select_age_inventory_list"
//                                 value={this.state.fuel_type}
//                                 onChange={this.handleChange.bind(this, 'fuel_type')}
//                                 options={fuel_type}
//                                 name="select_age_inventory_list"
//                                 placeholder="Age Of Inventory"
//                                 value={fuel_type.filter(({ value }) => value === this.state.fuel_type)}
//                                 getOptionLabel={({ label }) => label}
//                                 getOptionValue={({ value }) => value}
//                             />
//                         </div>
//                     </div>

//                     <div className="col-sm-6 col-md-2 form-group hide">
//                         <label>Select Owner</label>
//                         <div>
//                             <Select
//                                 components={{ IndicatorSeparator:() => null }}
//                                 id="select_owner_list"
//                                 value={this.state.fuel_type}
//                                 onChange={this.handleChange.bind(this, 'fuel_type')}
//                                 options={fuel_type}
//                                 name="select_owner_list"
//                                 placeholder="Select Owner"
//                                 value={fuel_type.filter(({ value }) => value === this.state.fuel_type)}
//                                 getOptionLabel={({ label }) => label}
//                                 getOptionValue={({ value }) => value}
//                             />
//                         </div>
//                     </div>


//                     <div className="col-sm-6 col-md-2 form-group hide">
//                         <label>Inspection Status</label>
//                         <div>
//                             <Select
//                                 components={{ IndicatorSeparator:() => null }}
//                                 id="inspection_status"
//                                 value={this.state.fuel_type}
//                                 onChange={this.handleChange.bind(this, 'fuel_type')}
//                                 options={fuel_type}
//                                 name="inspection_status"
//                                 placeholder="Select Inspection Status"
//                                 value={fuel_type.filter(({ value }) => value === this.state.fuel_type)}
//                                 getOptionLabel={({ label }) => label}
//                                 getOptionValue={({ value }) => value}
//                             />
//                         </div>
//                     </div>


//                     <div className="col-sm-6 col-md-2 form-group hide">
//                         <label>Transmission</label>
//                         <div>
//                             <Select
//                                 components={{ IndicatorSeparator:() => null }}
//                                 id="select_transmission_list"
//                                 value={this.state.fuel_type}
//                                 onChange={this.handleChange.bind(this, 'fuel_type')}
//                                 options={fuel_type}
//                                 name="select_transmission_list"
//                                 placeholder="Select Transmission"
//                                 value={fuel_type.filter(({ value }) => value === this.state.fuel_type)}
//                                 getOptionLabel={({ label }) => label}
//                                 getOptionValue={({ value }) => value}
//                             />
//                         </div>
//                     </div>

//                     <div className="col-sm-6 col-md-2 form-group hide">
//                         <div>
//                             <CheckBox type="checkbox" name="car-withoutPhotos" id="car-withoutPhotos" value="" label="Car Without Photos" />
//                         </div>
//                         <div>
//                             <CheckBox type="checkbox" name="car-withPhotos" id="car-withPhotos" value="" label="Car With Photos" />
//                         </div>
//                     </div>

//                     <div className="col-sm-6 col-md-2 form-group hide">
//                         <div>
//                             <CheckBox type="checkbox" name="isclassified_tab" id="isclassified_tab" value="" label="Classified Cars" />
//                         </div>
//                         <div>
//                             <CheckBox type="checkbox" name="nonclassified_tab" id="nonclassified_tab" value="" label="Non Classified Cars" />
//                         </div>
//                     </div>
//                     <div className="col-sm-6 col-md-2 form-group hide">
//                         <div>
//                             <CheckBox type="checkbox" name="car-Premium" id="car-Premium" value="" label="Featured Cars" />
//                         </div>
//                         <div>
//                             <CheckBox type="checkbox" name="trustmark-certified" id="trustmark-certified" value="" label="Trustmark Certified" />
//                         </div>
//                     </div>

//                     <div className="col-sm-6 col-md-2 form-group hide">
//                         <div>
//                             <CheckBox type="checkbox" name="car_with_issues" id="car_with_issues" value="" label="Car with issues" />
//                         </div>
//                         <div>
//                             <CheckBox type="checkbox" name="auction_c2d" id="auction_c2d" value="" label="Auction C2D" />
//                         </div>
//                     </div>



//                     <div className="col-sm-6 col-md-2">
//                         <label></label>
//                         <div> <button type="submit" className="btn btn-primary mrg-r10">Saerch</button><button type="reset" className="btn btn-default">Reset</button>
//                         </div>
//                     </div>

//                 </div>
//                 <a href="" className="advancesearch link-text"><span>+</span>Advance Search</a>
//             </div>
//         )
//     }
// }
// export default SearchStock;

import React, { Component } from 'react';
import Select from 'react-select';
import InputField from '../../elements/InputField';
import CheckBox from '../../elements/CheckBox';
import MmvDropDown from './../../enquiry/component/MmvDropDown';
import MasterService from './../../../service/MasterService';
import { withTranslation } from 'react-i18next';
import qs from 'query-string';
import { DISABLE_CERTIFICATION_STATUS_FIELD } from './../../../config/constant';

class SearchStock extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            fuel_type_id_data: [],
            body_type_data: [],
            certification_id_data: [{ key: '0', value: 'Non-inspected' }, { key: '1', value: 'Inspected' }],
            min_car_price_data: [],
            max_car_price_data: [],
            max_km_driven_data: [],
            uc_transmission_id_data: [],
            min_km_driven_data: [],
            inventory_age_data: [],
            stockCategory:this.props.stockCategory || 1,
            filterData:{}
        };
    }

    componentDidMount = async () => {
        await this.getFilterList();        
        let queryParams = this.props.location.search;        
        if(queryParams){
            let queryObject = qs.parse(queryParams);
            if(queryObject.non_classified){
                this.setState({'non_classified': '0'});
            }
            else if(queryObject.without_photos){
                this.setState({without_photos: 1});
            }
            else if(queryObject.car_age_before){
                this.setState({car_age_filter: 'over_45'});
            }
        }
    }

    getFilterList = async () => {
        var parentObj = this;
        await MasterService.get('inventory/inventory/getfilter', null).then((response) => {
            if (response.status == 200 && response.data.status == 200) {
                let responseData = response.data.data;
                for (let index in responseData) {
                    let keyValue = responseData[index]['key'];
                    if (keyValue === 'uc_transmission_id' || keyValue === 'fuel_type_id' || keyValue === 'body_type' || keyValue === 'min_km_driven' || keyValue === 'max_km_driven' || keyValue === 'min_car_price' || keyValue === 'max_car_price' || keyValue === 'inventory_age') {
                        parentObj.setState({
                            [keyValue + '_data']: responseData[index]['list']
                        });
                    }
                }
            }
        });
    }

    handleChange = (sname, ovalue) => {
        var field_value = (sname === 'car_make' || sname === 'car_model') ? ovalue.id : ovalue.key
        let filterData = this.state.filterData;
        filterData[sname]= field_value;
        this.setState({ filterData });
        if (typeof this.props.handleStockFilter === 'function') {
            this.props.handleStockFilter(sname, field_value);
        }
    }
    handleInputChange = (event) => {
        if (typeof this.props.handleStockFilter === 'function') {
            this.props.handleStockFilter(event.target.name, event.target.value);
        }
        let updatedState = this.state.filterData
        updatedState[event['target']['name']] = event['target']['value']

        this.setState({filterData: updatedState})
    }

    submitFilterForm = (event) => {
        event.preventDefault();
        if (typeof this.props.submitFilterForm === 'function') {
            this.props.submitFilterForm();
        }
    }

    resetFilter = () => {
        // let currentRoute= window.location.pathname//need to remove var not in use
        // window.location.href = currentRoute;
        // this.getFilterList()
        this.setState({ filterData:{}})
        this.props.submitFilterForm('reset');
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.submitFilterForm(e);
        }
    }

    handlechecked = (keyname) => event =>{
        let checkvalue='';
        if(event.target.checked){
            checkvalue=event.target.value;
            this.setState({ [keyname]: event.target.value });
        }else{
            checkvalue='';
            this.setState({ [keyname]: '' });
        } 
        if (typeof this.props.handlechecked === 'function') {
            this.props.handlechecked(keyname, checkvalue);
        } 
        let updatedState = this.state.filterData
        updatedState[keyname] = checkvalue

        this.setState({filterData: updatedState})

    }
    render() {
        const { fuel_type_id_data,body_type_data,certification_id_data, min_car_price_data, max_car_price_data, max_km_driven_data, uc_transmission_id_data, min_km_driven_data, inventory_age_data, stockCategory, filterData } = this.state;
        return (
            <div className="search-wrap">
                <ul className="search-flex">
                    <li className="searchitems">
                    <InputField
                            inputProps={{
                                id: "keyword",
                                type: "tel",
                                placeholder: "Search by reg no.",
                                name: "keyword",
                                autocompleate: "off",
                                label: this.props.t('listInventory.search_reg_no'),
                                value: filterData.keyword || ''
                            }}
                            onChange={this.handleInputChange}
                            onKeyPress={this.handleKeyPress}
                        />
                    </li>
                    <li className="searchitems doublew"><MmvDropDown filterData={filterData} stock_category_id={stockCategory} coloumnClass="col-sm-6 form-group" handleOptionChange={this.handleChange} multiSelectEnable={false}/></li>
                    <li className="searchitems doublew form-group">
                        <label>{this.props.t('listInventory.search_price_range')}</label>
                        <div className="row">
                            <div className="col-xs-6">
                                <Select
                                    components={{ IndicatorSeparator:() => null }}
                                    id="min_price"
                                    // value={min_car_price_data}//need to remove duplicate props
                                    onChange={this.handleChange.bind(this, 'min_price')}                                    
                                    options={min_car_price_data.filter(min_price => (filterData.max_price)? parseInt(min_price.key,10) <=  parseInt(filterData.max_price,10): min_price)}
                                    name="min_price"
                                    placeholder={this.props.t('listInventory.min')}
                                    value={min_car_price_data.filter(({ key }) => key === filterData.min_price)}
                                    getOptionLabel={({ value }) => value}
                                    getOptionValue={({ key }) => key}
                                />
                            </div>
                            <div className="col-xs-6">
                                <Select
                                    components={{ IndicatorSeparator:() => null }}
                                    id="max_price"
                                    // value={max_car_price_data}//need to remove duplicate props
                                    onChange={this.handleChange.bind(this, 'max_price')}
                                    options={max_car_price_data.filter(max_price => (filterData.min_price)? parseInt(max_price.key,10) >=  parseInt(filterData.min_price,10): max_price)}
                                    name="max_price"
                                    placeholder={this.props.t('listInventory.max')}
                                    value={max_car_price_data.filter(({ key }) => key === filterData.max_price)}
                                    getOptionLabel={({ value }) => value}
                                    getOptionValue={({ key }) => key}
                                />
                            </div>
                        </div>
                    </li>
                    <li className="searchitems doublew form-group">
                    <label>{this.props.t('listInventory.km_range')}</label>
                        <div className="row">
                            <div className="col-xs-6">
                                <Select
                                    components={{ IndicatorSeparator:() => null }}
                                    id="min_km_driven"
                                    // value={min_km_driven_data}//need to remove duplicate props
                                    onChange={this.handleChange.bind(this, 'min_km_driven')}
                                    options={min_km_driven_data}
                                    name="min_km_driven"
                                    placeholder={this.props.t('listInventory.from')}
                                    value={min_km_driven_data.filter(({ key }) => key === filterData.min_km_driven)}
                                    getOptionLabel={({ value }) => value}
                                    getOptionValue={({ key }) => key}
                                />
                            </div>
                            <div className="col-xs-6">
                                <Select
                                    components={{ IndicatorSeparator:() => null }}
                                    id="max_km_driven"
                                    // value={max_km_driven_data}//need to remove duplicate props
                                    onChange={this.handleChange.bind(this, 'max_km_driven')}
                                    options={max_km_driven_data}
                                    name="max_km_driven"
                                    placeholder={this.props.t('listInventory.to')}
                                    value={max_km_driven_data.filter(({ key }) => key === filterData.max_km_driven)}
                                    getOptionLabel={({ value }) => value}
                                    getOptionValue={({ key }) => key}
                                />
                            </div>
                        </div>
                    </li>
                    <li className="searchitems form-group">
                    <label>{this.props.t('listInventory.fuel_type')}</label>
                        <div>
                            <Select
                                components={{ IndicatorSeparator:() => null }}
                                id="fuel_type_id"
                                // value={fuel_type_id_data}//need to remove duplicate props
                                onChange={this.handleChange.bind(this, 'fuel_type_id')}
                                options={fuel_type_id_data}
                                name="fuel_type_id"
                                placeholder={this.props.t('listInventory.fuel')}
                                value={fuel_type_id_data.filter(({ key }) => key === filterData.fuel_type_id)}
                                getOptionLabel={({ value }) => value}
                                getOptionValue={({ key }) => key}
                            />
                        </div>
                    </li>
                    <li className="searchitems form-group">
                    <label>{this.props.t('listInventory.body_type')}</label>
                        <div>
                            <Select
                                components={{ IndicatorSeparator:() => null }}
                                id="car_body_type_id"
                                // value={body_type_data}//need to remove duplicate props
                                onChange={this.handleChange.bind(this, 'car_body_type_id')}
                                options={body_type_data}
                                name="car_body_type_id"
                                placeholder={this.props.t('listInventory.body')}
                                value={body_type_data.filter(({ key }) => key === filterData.car_body_type_id)}
                                getOptionLabel={({ value }) => value}
                                getOptionValue={({ key }) => key}
                            />
                        </div>
                    </li>
                    {!DISABLE_CERTIFICATION_STATUS_FIELD ?
                        <li className="searchitems">
                            <label>{this.props.t('listInventory.certification_status')}</label>
                            <div>
                                <Select
                                    components={{ IndicatorSeparator:() => null }}
                                    id="certification_status_id"
                                    // value={certification_id_data}//need to remove duplicate props
                                    onChange={this.handleChange.bind(this, 'certification_status_id')}
                                    options={certification_id_data}
                                    name="certification_status_id"
                                    placeholder={this.props.t('listInventory.certification_status')}
                                    value={certification_id_data.filter(({ key }) => key === filterData.certification_status_id)}
                                    getOptionLabel={({ value }) => value}
                                    getOptionValue={({ key }) => key}
                                />
                            </div>
                        </li>
                        : ''}
                    <li className="searchitems">
                    <label>{this.props.t('listInventory.transmission')}</label>
                        <div>
                            <Select
                                components={{ IndicatorSeparator:() => null }}
                                id="uc_transmission_id"
                                // value={uc_transmission_id_data}//need to remove duplicate props
                                onChange={this.handleChange.bind(this, 'uc_transmission_id')}
                                options={uc_transmission_id_data}
                                name="uc_transmission_id"
                                placeholder={this.props.t('listInventory.transmission')}
                                value={uc_transmission_id_data.filter(({ key }) => key === filterData.uc_transmission_id)}
                                getOptionLabel={({ value }) => value}
                                getOptionValue={({ key }) => key}
                            />
                        </div>
                    </li>
                    <li className="searchitems">
                    <label>Age Of Inventory</label>
                         <div>
                             <Select
                                 components={{ IndicatorSeparator:() => null }}
                                 id="car_age_filter"
                                //  value={this.state.stock_car_age}//need to remove duplicate props
                                 onChange={this.handleChange.bind(this, 'car_age_filter')}
                                 options={inventory_age_data}
                                 name="car_age_filter"
                                 placeholder="Age Of Inventory"
                                 value={inventory_age_data.filter(({ key }) => key === filterData.car_age_filter)}
                                 getOptionLabel={({ value }) => value}
                                 getOptionValue={({ key }) => key}
                             />
                        </div>
                    </li>
                    <li className="searchitems">
                    <label></label>
                    <div>
                            <CheckBox type="checkbox" name="without_photos" id="without_photos" value="1" label="Stock Without Photos" onChange={this.handlechecked('without_photos')} checked={(filterData.without_photos && filterData.without_photos=='1') ? 'checked' :''}/>
                            <CheckBox type="checkbox" name="with_photos" id="with_photos" value="1" label="Stock With Photos" onChange={this.handlechecked('with_photos')} checked={(filterData.with_photos && filterData.with_photos=='1') ? 'checked' :''}/>
                        </div>
                    </li>
                    {stockCategory == 1 ?
                        <li className="searchitems">
                            <label></label>
                            <CheckBox type="checkbox" name="classified" id="classified" value="1" label="Classified Cars" onChange={this.handlechecked('classified')} checked={(filterData.classified && filterData.classified == '1') ? 'checked' : ''} />
                            <CheckBox type="checkbox" name="non_classified" id="non_classified" value="0" label="Non Classified Cars" onChange={this.handlechecked('non_classified')} checked={(filterData.non_classified && filterData.non_classified == '0') ? 'checked' : ''} />
                        </li> : ''}
                    {stockCategory == 1 ?
                        <li className="searchitems">
                            <label></label>
                            <CheckBox type="checkbox" name="bursa" id="bursa" value="1" label="Bursa" onChange={this.handlechecked('is_bursa')} checked={(filterData.is_bursa && filterData.is_bursa == 1) ? 'checked' : ''} />
                        </li> : ''}
                    <li className="searchitems  form-group">
                    <label></label>
                        <div>
                            <button type="submit" onClick={this.submitFilterForm} className="btn btn-primary mrg-r10">{this.props.t('listInventory.search')}</button>
                            <button type="button" onClick={this.resetFilter} className="btn btn-reset">{this.props.t('listInventory.reset')}</button>
                        </div>
                    </li>
                    <li className="searchitems"></li>
                </ul>
                
            </div>
        )
    }
}
export default withTranslation('inventory')(SearchStock);
