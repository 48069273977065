import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { LINK_URL,CONF_VAL } from './../../../config/constant';

class LeftPanel extends Component {

    render() {
        return (
            <div className="leftBlueBg">
                <div className="blueBg">
                <div className="logoSec"><img src={require('./../assets/images/'+LINK_URL.LOGO)} alt=''/></div>
                    <h1 className="lgHeading"><strong>{this.props.t('login.grow')}</strong> <span className="txt87">{this.props.t('login.your_business')}</span></h1>
                    <p>{this.props.t('login.business_text')}</p>
                    <div className="playStore">
                        <a href="http://play.google.com/store/apps/details?id=com.oto.dealer.id" target="_blank" rel="noreferrer"><img src={require('./../assets/images/google-play.png')} alt="" /></a>
                        <a href="https://itunes.apple.com/us/app/oto-dealer/id1489698884?ls=1&amp;mt=8" target="_blank" rel="noreferrer"><img src={require('./../assets/images/app-store.png')} alt="" /></a>
                    </div>
                    <div className="homeMobile">
                        <img className="img-mobile-bg" src={require('./../assets/images/'+CONF_VAL.MOBILE_LOGIN_IMAGE)} alt="" />
                        <img className="img-mobile-bg1" src={require('./../assets/images/'+CONF_VAL.MOBILE_LOGIN_IMAGE)} alt="" />
                        {/* <video width="229" className="true" className="videoSec">
                            <source src={require('./../assets/images/videos.mp4')} type="video/mp4" />
                            {this.props.t('login.not_support')}
                        </video> */}
                        
                    </div>
                    <div className="animationImage">
                        <img src={require('./../assets/images/car_animation_new.png')} alt="" />
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation('common')(LeftPanel);