import React, { Component } from 'react';
// import Select from 'react-select';//need to remove
import MmvDropDown from './MmvDropDown';

// const make = [//need to remove not in use
//     { value: 'maruti', label: 'Maruti' },
//     { value: 'hyundai', label: 'Hyundai' },
//     { value: 'honda', label: 'Honda' },
//     { value: 'tata', label: 'Tata' },
//     { value: 'mahindra', label: 'Mahindra' },
//     { value: 'renault', label: 'Renault' },
//     { value: 'ford', label: 'Ford' },
//     { value: 'nissan', label: 'Nissan' }
// ];

// const model = [//need to remove not in use
//     { value: 'maruti', label: 'Maruti' },
//     { value: 'hyundai', label: 'Hyundai' },
//     { value: 'honda', label: 'Honda' },
//     { value: 'tata', label: 'Tata' },
//     { value: 'mahindra', label: 'Mahindra' },
//     { value: 'renault', label: 'Renault' }
// ];

// const varient = [
//     { value: 'maruti', label: 'Maruti' },
//     { value: 'hyundai', label: 'Hyundai' },
//     { value: 'honda', label: 'Honda' },
//     { value: 'tata', label: 'Tata' },
//     { value: 'mahindra', label: 'Mahindra' },
//     { value: 'renault', label: 'Renault' }
// ];
class CustomStock extends Component {
    constructor(props){
        super(props);
        this.state = {
            formData: {}
        };
    }
    handleChange = (sname, ovalue) => {
        let option_value = (sname==='car_version') ? ovalue.vn_id : ovalue.id;
        if (typeof this.props.customStockChange === 'function') {
            this.props.customStockChange(sname, option_value);
        }
    }
    render() {
        return (
            <div className="clearfix">
                <MmvDropDown coloumnClass="col-sm-3 form-group" showVersion='1' handleOptionChange={this.handleChange} />
            </div>
        );
    }
}

export default CustomStock;