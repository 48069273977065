import UserService from "../../service/DealerService";

export const UserActions = {
    loginCaptcha
};

function loginCaptcha() {
    return dispatch => {
        return new Promise((resolve, reject) => {

            UserService.loginCaptcha()
                .then(
                    captcha => {
                        if (captcha) {
                            if (captcha.data && captcha.data.status === 200) {
                                dispatch(success(captcha.data.data));
                            } else {
                                dispatch(failure(captcha.data.message));
                            }
                        } else {
                            dispatch(failure('error'));
                        }

                        resolve(captcha)
                    },
                    error => {
                        dispatch(failure(error));
                        reject(error)
                    }
                )
        })
    }

    function success(captcha) { return { type: "USERS_LOGIN_SUCCESS", captcha } }
    function failure(error) { return { type: "USERS_LOGIN_CAPTCHA", error } }
}