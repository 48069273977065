import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { SITE_CONTENTS } from './../../../config/constant';
import emailIcon from './../assets/images/email.svg';
import mobileIcon from './../assets/images/mobile.svg';
import addressIcon from './../assets/images/address.svg';
class ContactUs extends Component {

    render() {
        return (
            <div className="item os-animation animated">
                <div id="divSec" className="row os-animation animated fadeInUp" data-os-animation="fadeInUp" data-os-animation-delay="0.50s">
                    <div className="col-md-12 clearfix" id="modified_design">
                        <div className="signUp-container text-left">
                            <h1 className="login-h1">{this.props.t('site_content.contact_us')}</h1>
                            <p className="login-p">{this.props.t('site_content.get_in_touch')}</p>

                            <div className="row">
                                <div className="col-md-12 clearfix">
                                    <div className="contact_Ic">
                                        <img src={emailIcon} alt="" />
                                    </div>
                                    <div className="contactInfo">
                                        {SITE_CONTENTS.DEALER_SUPPORT_EMAIL}
                                    </div>
                                </div>
                                <div className="col-md-12 clearfix">
                                    <div className="contact_Ic">
                                        <img src={mobileIcon} alt="" />
                                    </div>
                                    <div className="contactInfo">
                                        {SITE_CONTENTS.DEALER_SUPPORT_PHONE}
                                    </div>
                                </div>
                                <div className="col-md-12 clearfix">
                                    <div className="contact_Ic cust-cont">
                                        <img src={addressIcon} alt="" />
                                    </div>
                                    <div className="contactInfo">
                                        {SITE_CONTENTS.SITE_NAME}
                                        <p className="login-p mrg-L50 smtxt">{SITE_CONTENTS.SITE_ADDRESS}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation('common')(ContactUs);