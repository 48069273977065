import React, { Component } from 'react';
import LeftPanel from './left-panel';
import RemoveInfoForm from './remove-info-form';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import MasterService from './../../../service/MasterService';

class RemoveInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {"captchaVal":"", captchaId : '', otpId: '', otpValue:''},
            errors: {},
            key: 1,
            loading: false,
            redirect: 0,
            show: false,
            reloadCaptcha:1,
            showOtp:false,
        };
    }

    componentDidMount() {
        // if (secureStorage.getItem('loginUserInfo')) {
        //     var loginResponse = secureStorage.getItem('loginUserInfo');
        //     this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
        //     this.afterLoginSetState(loginResponse)
        // }
    }

   
    handleChangeEvent = event => {        
        let fields = {...this.state.fields};
        const errors = {...this.state.errors};
        // eslint-disable-next-line eqeqeq
        if(event && event.captchaData && event.captchaData.status==200){    
            fields["captchaVal"] = event.captchaVal;
            fields['captchaId'] = event.captchaData.data.value;
            // eslint-disable-next-line eqeqeq
            if(event.captchaVal ==''){
                errors['captchaErr'] = 'Please enter valid captcha';
            }
        }else{
            fields[event.target.name] = event.target.value;
            if(event.target.value !== ''){
                delete errors[event.target.name];
            }else{
                errors[event.target.name] = 'Please enter '+event.target.name;
            }
        }                
        this.setState({ fields: fields,errors: errors });
    }

    submitAndValidateLoginForm = (event) => {
        event.preventDefault();
        let self = this
        let fields = {...this.state.fields};
        const errors = {...this.state.errors};
        // this.setState({loading:true})
        if (this.validForm()) {
            self.setState({loading:true})
            fields.withCaptcha = self.state.showOtp ? false : true
            MasterService.post('account/user/remove-info', fields)
                .then((response) => {
                    // eslint-disable-next-line eqeqeq
                    if (response.status == 200 && response.data.status == 200 && response.data) {
                        if (fields.withCaptcha) {
                            self.sendOtpEmail()
                        }
                        // eslint-disable-next-line eqeqeq
                        if(response.status == 200 && response.data.status == 200 && response.data.message == "Valid Otp"){
                           this.setState({showOtp:false})
                           toast.success("Your request has been submitted successfully, you will get a confirmation email shortly.");
                        }
                    } else {
                         

                        window.commonErrorToast(response.data.message)
                        // eslint-disable-next-line eqeqeq
                        if (response.data.errors[0] && response.data.errors[0] == "ACCOUNT_BLOCKED") {
                            errors['email'] = response.data.message;
                            this.setState({ errors: errors });
                        } else {
                            delete errors['email'];
                        }
                        // self.setState({loading:false})
                        this.setState({ reloadCaptcha: !(this.state.reloadCaptcha), fields: { ...this.state.fields, "captchaVal": "" } });
                    }
                }).catch(function (error) {
                    this.setState({loading: false})
                    window.commonErrorToast(error.message);
                });
                self.setState({loading:false})
        }
        // this.setState({loading:false})
    }

    sendOtpEmail = () => {
        let self = this
        let fields = {...this.state.fields};
        fields.withCaptcha = self.state.showOtp ? false : true
        let sendData = {
            email: fields.email
        }
        MasterService.post('account/user/send_email_otp', sendData)
            .then(function (response) {
                // eslint-disable-next-line eqeqeq
                if (response.status == 200 && response.data.status == 200) {
                    if (response.data.data && response.data.data.value) {
                        fields["otpId"] = response.data.data.value
                        self.setState({ fields , showOtp: true})
                        toast.success(response.data.message)
                    }
                    self.setState({loading:false})
                } else {
                    self.setState({loading:false})
                    window.commonErrorToast(response.data.message);
                }
            }).catch(function (error) {
                self.setState({loading:false})
                window.commonErrorToast(error.message);
            });
    }

    validForm = ()=>{
        let fields = {...this.state.fields};
        const errors = {...this.state.errors}; 
        let showOtp = this.state.showOtp       
        let formIsValid = true;         
        errors['captchaErrorMessage'] = '';
        if(showOtp){
            if (errors["otpValue"] || (!fields.otpValue)) {  
                if(!fields["otpValue"]){
                    errors['otpValue'] = 'OTP is required';  
                    errors['OtpErrorMessage'] = 'OTP is required';
                } else if(errors["otpValue"]){
                    errors['otpValue'] = 'Please enter valid OTP';  
                    errors['OtpErrorMessage'] = 'Please enter valid OTP';
                }                   
                formIsValid = false;
                // eslint-disable-next-line eqeqeq
            }else if(fields.otpValue.length != 6){
                errors['otpValue'] = 'Please enter valid OTP';  
                errors['OtpErrorMessage'] = 'Please enter valid OTP';
                formIsValid = false;
            } else if(errors["otpValue"]){
                errors['otpValue'] = 'Please enter valid OTP';  
                errors['OtpErrorMessage'] = 'Please enter valid OTP';
                formIsValid = false;
            } 
        } else {
            if (!fields["email"]) {
                errors['email'] = this.props.t('login.enter_email');
                formIsValid = false;
                // eslint-disable-next-line no-useless-escape
            }else if(! /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(fields["email"])){
                errors['email'] = this.props.t('login.valid_email');
                formIsValid = false;
            }
            
            if (errors["captchaErr"] || (!fields["captchaVal"])) {  
                if(!fields["captchaVal"]){
                    errors['captchaErr'] = 'Captcha is required';  
                    errors['captchaErrorMessage'] = 'Captcha is required';
                }else if(errors["captchaErr"]){
                    errors['captchaErr'] = 'Please enter valid captcha';  
                    errors['captchaErrorMessage'] = 'Please enter valid captcha';
                    this.setState({ reloadCaptcha: !(this.state.reloadCaptcha) });
                }                   
                formIsValid = false;
            }
        }
        
        
        this.setState({errors: errors});
        return formIsValid;
    }


    render() {
       
        
        let  dynamicComponent = <RemoveInfoForm errorMessage={this.state.errors} showOtp={this.state.showOtp} onClick={this.submitAndValidateLoginForm} onChange={this.handleChangeEvent} reloadCaptcha={this.state.reloadCaptcha} sendOtpEmail={this.sendOtpEmail} loading={this.state.loading}/>; 
        return (
            <div className={this.state.loading?"container-fluid loading":"container-fluid"} id="homePage">
                <LeftPanel />
                
                <div className="rightWhiteBg">
                    <nav className="navbar" role="navigation">
                        {/* <ul className="nav navbar-nav">
                            <li data-key="login" className={contentSection == 'login' ? 'active' : ''} onClick={this.changeContent}>{this.props.t('login.login')}</li>
                           
                        </ul> */}
                    </nav>
                    <section className="slide-wrapper">
                        <div id="myCarousel" className="carousel slide carousel-fade">
                            <ol className="carousel-indicators">
                                {/* <li data-key="login" className={contentSection == 'login' ? 'active' : ''} onClick={this.changeContent}></li> */}
                                {/* <li data-key="joinus" className={contentSection == 'joinus' ? 'active' : ''} onClick={this.changeContent}></li> */}
                                {/* <li data-key="contactus" className={contentSection == 'contactus' ? 'active' : ''} onClick={this.changeContent}></li> */}
                            </ol>
                            <div className="carousel-inner">
                                {dynamicComponent}
                            </div>
                        </div>
                    </section>
                    {/* <footer>
                        <div className="footer">{this.props.t('site_content.copyright')}</div>
                    </footer> */}
                </div>
            </div>
        );
    }
}
// const mapStateToProps = state => {
//     return {
//         user_loggedin: state.userLoggedIn
//     };
// }

// const mapDispatchToProps = dispatch => {
//     return {
//         updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
//     }
// }
export default withTranslation('common')(RemoveInfo);