import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import MasterService from "../../../service/MasterService";
import { DealerService, InventoryService } from "../../../service";
import { format } from 'date-fns';
import { toast } from "react-toastify";

class ViewHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            basic_detail_history: [],
            account_detail_history: [],
            showroomHistory: [],
            kycHistory: [],
            activeTab: 'basic',
            bankList:[],
            StateList: [],
            cityList: [],
            shared_doc_history: [],
            vendor_history: []
        };
    }

    getBankList = async () => {
        DealerService.getMasters(["bank_list"])
            .then((response) => {
                if (response && response.status == 200 && response.data.status == 200) {
                    this.setState({ bankList: response.data.data.bank_list });
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
            });
    };

    getHistoryDetails() {
        MasterService.post("/dealer/dealer/view_dealer_history", { dealer_id: this.props.histoy_dealer_id })
            .then((resp) => {
                if (resp.status === 200) {
                    this.setState({ basic_detail_history: resp.data.data.detail_history });
                    this.setState({ 
                        account_detail_history: resp.data.data.account_history,
                        showroomHistory: resp.data.data.showroomHistory,
                        kycHistory: resp.data.data.kycHistory,
                        vendor_history: resp.data.data.vendor_history || []
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    getShareDocDetails() {
        MasterService.post("/dealer/dealer/shared_dealer_doc_history", { dealer_id: this.props.histoy_dealer_id })
            .then((resp) => {
                console.log(resp, '22')
                if (resp.status === 200) {
                    this.setState({ shared_doc_history: resp.data.data });
                }
            })
            .catch((err) => console.log(err));
    }

    handleTabClick = (tabName) => {
        this.setState({ activeTab: tabName });
    };

    componentDidMount() {
        this.getHistoryDetails();
        this.getBankList();
        this.getShareDocDetails();
        this.getCityList()
    }

    getCityList() {
        InventoryService.cityList({}).then(response => {
            if (response.data.status == 200 && response.status==200) {
                this.setState({StateList: response.data.data.state,
                    cityList: response.data.data.city
                })
            }else{
                toast.error( (response.data.message) ? response.data.message : "Error" )
            }
        }).catch(error => {
            toast.error(error.message);
        });
        this.getShareDocDetails();
    }

    renderTabContent() {
        const { activeTab, basic_detail_history, account_detail_history, bankList, shared_doc_history, cityList, StateList, showroomHistory, kycHistory, vendor_history } = this.state;
        switch (activeTab) {
            case "basic":
                return <BasicDetails history={basic_detail_history} />;
            case "account":
                return <AccountDetails history={account_detail_history} bankList={bankList} />;
            case "shared_doc":
                return <SharedDocDetails history={shared_doc_history} />;
            case "outlet":
                return <OutletDetails history={showroomHistory} cityList={cityList} StateList={StateList}/>;
            case "kyc":
                return <KYCDocDetails history={kycHistory} />;
            case "vendor":
                return <VendorDetails history={vendor_history} />;
            default:
                return <div>Selected tab content not available</div>;
        }
    }

    render() {
        const { loading, activeTab } = this.state;

        return (
            <div>
                <div className={loading ? "tab-list loading" : "tab-list"}>
                    <a
                        className={`nav-link ${this.state.activeTab === "basic" ? "active" : ""}`}
                        onClick={(e) => this.handleTabClick("basic", e)}
                        href={() => false}
                    >
                        Basic Details
                    </a>
                    <a
                        className={`nav-link ${this.state.activeTab === "account" ? "active" : ""}`}
                        onClick={(e) => this.handleTabClick("account", e)}
                        href={() => false}
                    >
                        Account Details
                    </a>
                    <a 
                        className={`nav-link ${this.state.activeTab === 'outlet' ? 'active' : ''}`} 
                        onClick={(e) => this.handleTabClick('outlet', e)} 
                        href={() => false}
                    >
                        Outlet Details History
                    </a>
                    <a 
                        className={`nav-link ${this.state.activeTab === 'kyc' ? 'active' : ''}`} 
                        onClick={(e) => this.handleTabClick('kyc', e)} 
                        href={() => false}
                    >
                        KYC Details History
                    </a>
                    <a
                        className={`nav-link ${this.state.activeTab === "shared_doc" ? "active" : ""}`}
                        onClick={(e) => this.handleTabClick("shared_doc", e)}
                        href={() => false}
                    >
                        Shared Docs Details
                    </a>
                    <a
                        className={`nav-link ${this.state.activeTab === "vendor" ? "active" : ""}`}
                        onClick={(e) => this.handleTabClick("vendor", e)}
                        href={() => false}
                    >
                        Vendor History
                    </a>
                </div>
                <div className="tab-content">{this.renderTabContent()}</div>
            </div>
        );
    }
}

const BasicDetails = ({ history }) => {
    return (
        <table style={{ width: "100%", tableLayout: "fixed" }}>
            <thead>
                <tr>
                    <th>Dealership Name</th>
                    <th>Dealership Email</th>
                    <th>Dealer Contact No.</th>
                    <th>Owner Name</th>
                    <th>Owner Email</th>
                    <th>Owner Contact No.</th>
                    <th>Created By and DateTime</th>
                    <th>Approval date</th>
                </tr>
            </thead>
            <tbody>
                {history.map((item, idx) => {
                    let new_created_date = format(new Date(item.created_date), "do MMMM yyyy, h:mm a");
                    let new_approval_date = item.approved_date ? format(new Date(item.approved_date), 'do MMMM yyyy, h:mm a') : "-";
                    return (
                        <tr>
                            <td style={{ wordWrap: 'break-word' }}>{item.dealership_name}</td>
                            <td style={{ wordWrap: 'break-word' }}>{item.dealership_email}</td>
                            <td style={{ wordWrap: 'break-word' }}>{item.dealership_contact_no}</td>
                            <td style={{ wordWrap: 'break-word' }}>{item.owner_name}</td>
                            <td style={{ wordWrap: 'break-word' }}>{item.owner_email}</td>
                            <td style={{ wordWrap: 'break-word' }}>{item.owner_contact_no}</td>
                            <td style={{ wordWrap: 'break-word' }}>{item.created_by}<br />{new_created_date}</td>
                            <td style={{ wordWrap: 'break-word' }}>{new_approval_date}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

const AccountDetails = ({ history, bankList }) => {
    return (
        <table style={{ width: "100%", tableLayout: "fixed" }}>
            <thead>
                <tr>
                    <th>Bank Name</th>
                    <th>Bank Account No.</th>
                    <th>Beneficiary Name</th>
                    <th>Status</th>
                    <th>Created By and DateTime</th>
                </tr>
            </thead>
            <tbody>
                {history.map((item, idx) => {
                    let new_created_date = format(new Date(item.created_date), "do MMMM yyyy, h:mm a");
                    let bank_name = bankList.find((bank) => parseInt(bank.bank_id) === parseInt(item.bank_id))?.name || "N/A";
                    return (
                        <tr key={idx}>
                            <td>{bank_name}</td>
                            <td>{item.bank_account_no}</td>
                            <td>{item.beneficiary_name}</td>
                            <td>{item.bank_status === "1" ? "Active" : "Inactive"}</td>
                            <td>{`${item.created_by} ${new_created_date}`}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

const SharedDocDetails = ({ history }) => {
    return (
        <table style={{ width: "100%", tableLayout: "fixed" }}>
            <thead>
                <tr>
                    <th>Shared By</th>
                    <th>Shared To</th>
                    <th>Docs Shared List</th>
                    <th>Reason for Sharing</th>
                    <th>Docs Shared Datetime</th>
                </tr>
            </thead>
            <tbody>
                {history.map((item, idx) => {
                    return (
                        <tr key={idx}>
                            <td>{item.shared_by}</td>
                            <td>{item.shared_to}</td>
                            <td>{item.docs_shared_list}</td>
                            <td>{item.reason_for_sharing}</td>
                            <td>{item.docs_shared_datetime}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

const OutletDetails = ({ history, cityList, StateList }) => {
    return (
        <table style={{ width: '100%', tableLayout: 'fixed' }}>
            <thead>
                <tr>
                    <th>Outlet Name</th>
                    <th>Outlet Contact Number</th>
                    <th>Outlet address</th>
                    <th>City and State</th>
                    <th>Latitude</th>
                    <th>Longitude</th>
                    <th>Edited date and time</th>
                </tr>
            </thead>
            <tbody>
                {history.map((item, idx) => {
                    let new_created_date = format(new Date(item.created_date), 'do MMMM yyyy, h:mm a');
                    let address = item.address.split("|").join(" | ")
                    if (item.description) {
                        address += " " + item.description
                    }
                    let city_name = cityList.findIndex(e => e.id == item.city_id) > -1 ? cityList.filter(e => e.id == item.city_id)[0].name : ""
                    let state_name = StateList.findIndex(e => e.id == item.state_id) > -1 ? StateList.filter(e => e.id == item.state_id)[0].name : ""
                    return (
                        <tr>
                            <td >{item.name}</td>
                            <td >{item.contact_number}</td>
                            <td >{address}</td>
                            <td >{city_name + ", " + state_name}</td>
                            <td >{item.latitude}</td>
                            <td >{item.longitude}</td>
                            <td >{new_created_date}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

const KYCDocDetails = ({ history }) => {
    return (
        <table style={{ width: '100%', tableLayout: 'fixed' }}>
            <thead>
                <tr>
                    <th>KYC Category</th>
                    <th>KYC Document</th>
                    <th>KYC Document Link</th>
                    <th>Verified Date</th>
                    <th>Verified by</th>
                    <th>Added Date</th>
                    <th>Deleted Date</th>
                </tr>
            </thead>
            <tbody>
                {history.map((item, idx) => {
                    let new_verified_date = item.verified_date ? format(new Date(item.verified_date), 'do MMMM yyyy, h:mm a') : "";
                    let new_created_date = item.doc_added_date ? format(new Date(item.doc_added_date), 'do MMMM yyyy, h:mm a') : "";
                    let new_deleted_date = item.doc_deleted_date ? format(new Date(item.doc_deleted_date), 'do MMMM yyyy, h:mm a') : "";
                    return (
                        <tr>
                            <td >{item.document_category}</td>
                            <td >{(item.kyc_child_id ? item.document_name : "Other") +(item.doc_number ?"-"+ item.doc_number : "")}</td>
                            <td>
                            {item.kyc_doc_images && item.kyc_doc_images.length > 0 ? item.kyc_doc_images.map((imageEle, idx) =>
                                imageEle ? <><a href={imageEle} target="_blank">Click Here</a> <br/></>: ""
                            ) : ''}
                            </td>
                            <td >{new_verified_date}</td>
                            <td >{item.verified_by}</td>
                            <td >{new_created_date}</td>
                            <td >{new_deleted_date}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

const VendorDetails = ({ history }) => {
    return (
        <table style={{ width: '100%', tableLayout: 'fixed' }}>
            <thead>
                <tr>
                    <th>Created On</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Vendor ID</th>
                </tr>
            </thead>
            <tbody>
                {history.map((item, idx) => {
                    let new_created_date = item.created_date ? format(new Date(item.created_date), 'do MMMM yyyy, h:mm a') : "";
                    return (
                        <tr>
                            <td>{new_created_date}</td>
                            <td>{item.show_type}</td>
                            <td>{item.show_response_status}</td>
                            <td>{item.vendor_id}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

const mapStateToProps = (state) => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData }),
    };
};

export default withTranslation("dealers")(connect(mapStateToProps, mapDispatchToProps)(ViewHistory));
