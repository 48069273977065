import React, { Component } from 'react';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

class AddSKU extends Component {

    constructor(props) {
        super(props);
        this.state = {
            key: 1,
            skus: props.skus,
            validation_error_msg: "",
            sku: "",
        };
    }
    handleChange = (sname, ovalue) => {
        this.setState({
            validation_error_msg: "",
            [sname]: ovalue.value
        });
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.setState({validation_error_msg: ""});
        let sku = this.state.skus.filter(v=>v.id==e.target.sku.value)[0]
        if (sku) {
            this.props.modalSubmit(sku)
        }
        else {
            this.setState({validation_error_msg: "This field required."});
        }
    }
    // handleSelect(key) {
    //     this.setState({ key });
    // }
    // handleChangetext = (event) => {
    //     this.setState({
    //         [event.target.name]: event.target.value
    //     });
    // }
    render() {
        return (
            <div>
                <form name="form_add_sku" onSubmit={this.onSubmit}>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label>{this.props.t('dealerDetails.subscription_details.Choose_SKU')}*</label>
                            <Select
                                components={{ IndicatorSeparator:() => null }}
                                id="sku"
                                value={this.state.sku}
                                onChange={this.handleChange.bind(this, 'sku')}
                                options={this.state.skus}
                                name="sku"
                                placeholder={this.props.t('dealerDetails.subscription_details.Choose_SKU')}
                                // value={sku.filter(({ value }) => value === this.state.sku)}
                                getOptionLabel={({ sku_name }) => sku_name}
                                getOptionValue={({ id }) => id}
                            />
                            {this.state.validation_error_msg && 
                            <div className="error show" >{this.state.validation_error_msg}</div>
                            }
                        </div>
                        <div className="col-md-12 form-group text-right">
                        <button onClick={this.props.modalClose} type="reset" id="cancel" name="cancel" className="btn btn-link mrg-r15">{this.props.t('dealerDetails.subscription_details.Cancel')}</button>
                            <button type="submit" id="savedetail" name="submit" className="btn btn-primary">{this.props.t('dealerDetails.subscription_details.Add')}</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(AddSKU));
//export default withTranslation('dealers')(AddSKU); 