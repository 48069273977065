import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { Redirect, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import InputField from './../../elements/InputField';
import Accordion from './../../elements/Accordion';
import { withTranslation } from 'react-i18next';
import MasterService from './../../../service/MasterService';
// import secureStorage from './../../../config/encrypt';//need to remove
import {MOBILE_VALIDATION,OWNER_ID,PASSWORD_VALIDATION} from './../../../config/constant';
class EmployeeDetails extends Component {

    constructor(props) {
        super(props);
        this.addNewDelearEmployee = this.addNewDelearEmployee.bind(this);
        this.deleteNewCreatedEmployee = this.deleteNewCreatedEmployee.bind(this);
        this.newPasswordEnable = this.newPasswordEnable.bind(this);

        this.state = {
            roleList: [],
            loading: true,
            updateEmployeeData: {},
            getDealerEmployeeList: [],
            basic_details: {},
            errors: {},
            role_access: this.props.role_access || [],
            addNewUserData: {
                dealer_id_hash: this.props.dealer_id_hash
            },
            getDealerEmployeeData: {
                dealer_id_hash: this.props.dealer_id_hash
            },
            addNewUSer: false,
            redirect: false,
            passwordDesibled: 'true',
        };
    }

    componentDidMount = async () => {
        this.getDealerEmployeeDetails();
        this.loadEmployeeRoleList();
    }


    componentWillReceiveProps(nextProps) {
        this.setState({
            basic_details: nextProps.basic_details,
            owner_details: nextProps.owner_details,
            dealership_list: nextProps.dealership_list,
            responseMsg: nextProps.responseMsg,
            role_access: nextProps.role_access || [],
        });
    }

    loadEmployeeRoleList = () => {
        MasterService.get('core/commonservice/master?master[]=dealer_role',
            { 'headers': { 'Authorization': this.props.auth_token } })
            .then((response) => {
                if (response.status == 200 && response.data.status == 200) {
                    response.data.data.dealer_role = response.data.data.dealer_role.filter(e=>e.id != OWNER_ID)
                    this.setState({
                        roleList: response.data.data.dealer_role
                    })
                } else if (response.status == 401 && response.data.status == 401) {
                    this.setState({ redirect: true })
                }
            }).catch((error) => {
                toast.error(error)
                this.setState({ loading: false })
            });
    }

    getDealerEmployeeDetails = async () => {
        MasterService.post('dealer/user-manager/list', this.state.getDealerEmployeeData, {
            'headers': { 'Authorization': this.props.auth_token }
        }).then((response) => {
            if (response.status == 200 && response.data.status == 200) {
                response.data.data = response.data.data.filter(e=>e.user_type != OWNER_ID)
                this.setState({ getDealerEmployeeList: response.data.data, loading: false });
            } else {
                this.setState({ redirect: true, loading: false })
            }
        }).catch((error) => {
            toast.error(error)
            this.setState({ loading: false })
        })
    }

    addDealerEmployeeDetails = (event) => {
        let { addNewUserData } = this.state;
        addNewUserData.user_name = addNewUserData.email;
        if (this.validForm()) {
            this.setState({ loading: true })
            MasterService.post('dealer/user-manager/add', this.state.addNewUserData, {
                'headers': { 'Authorization': this.props.auth_token }
            }).then((response) => {
                if (response.status == 200 && response.data.status == 200) {
                    toast.success(this.props.t('dealerDetails.Basic_Employee_Details.Customer_Added'));
                    this.getDealerEmployeeDetails();
                    this.setState({ addNewUSer: false, loading: false });
                    this.setState(() => ({ addNewUserData:{} }))
                    this.setState(() => ({ addNewUserData:{dealer_id_hash: this.props.dealer_id_hash} }))
                 } else {
                    this.setState({ loading: false })
                    toast.error(response.data.message)
                }
            }).catch((error) => {
                toast.error(error)
                this.setState({ loading: false })
            });
        }
    }

    updateDealerEmployeeDetails = async (event) => {
        let { getDealerEmployeeList, updateEmployeeData } = this.state;
        var index = Number(event.target.attributes.getNamedItem('data-index').value);
        if (this.validUpdateForm(index)) {
            this.setState({ loading: true })
            updateEmployeeData.dealer_id_hash = this.props.dealer_id_hash;
            updateEmployeeData.id = getDealerEmployeeList[index].id;
            updateEmployeeData.name = getDealerEmployeeList[index].name;
            updateEmployeeData.email = getDealerEmployeeList[index].email;
            updateEmployeeData.birth_date = '';
            updateEmployeeData.date_of_joining = '';
            if (getDealerEmployeeList[index].new_password) {
                updateEmployeeData.new_password = getDealerEmployeeList[index].new_password;
            } else {
                delete updateEmployeeData['new_password'];
            }
            updateEmployeeData.mobile = getDealerEmployeeList[index].mobile;
            if (getDealerEmployeeList[index].birth_date) {
                updateEmployeeData.birth_date = getDealerEmployeeList[index].birth_date;
            }
            if (getDealerEmployeeList[index].date_of_joining) {
                updateEmployeeData.date_of_joining = getDealerEmployeeList[index].date_of_joining;
            }
            updateEmployeeData.user_type = getDealerEmployeeList[index].user_type;

            MasterService.put('dealer/user-manager/edit', updateEmployeeData, {
                'headers': { 'Authorization': this.props.auth_token }
            }).then((response) => {
                if (response.status == 200 && response.data.status == 200) {
                    toast.success(this.props.t('dealerDetails.Basic_Employee_Details.Updated_Successfully'));
                    this.getDealerEmployeeDetails();
                    this.setState({ loading: false })
                } else {
                    
                    this.setState({ loading: false })
                    toast.error(response.data.message);
                } 
            }).catch((error) => {
                toast.error(error)
                this.setState({ loading: false })
            })
        }
    }

    deleteEmployeeDetails(id) {
        const deleteEmpObject = {
            "id": id,
            "dealer_id_hash": this.props.dealer_id_hash
        }
        MasterService.delete('dealer/user-manager',
            {
                'headers': { 'Authorization': this.props.auth_token },
                data: deleteEmpObject
            }).then((response) => {
                if (response.status == 200 && response.data.status == 200) {
                    toast.success(this.props.t('dealerDetails.Basic_Employee_Details.Customer_Deleted'));
                    this.getDealerEmployeeDetails();
                } else {
                    this.setState({ loading: false })
                    toast.error(response.data.errors ? response.data.errors[0] : 'Error')
                }
            }).catch((error) => {

                toast.error(error)
                this.setState({ loading: false })
            });
    }

    newPasswordEnable = event => {
        var edflag = event.target.attributes.getNamedItem('data-flag').value;
        var index = event.target.attributes.getNamedItem('data-index').value;
        let getDealerEmployeeList = this.state.getDealerEmployeeList;
        if (edflag == 'true') {
            getDealerEmployeeList[index]['new_password'] = "";
        }
        this.setState({ passwordDesibled: edflag });
        this.setState({
            getDealerEmployeeList
        });
    }

    addNewDelearEmployee() {
        this.setState({ addNewUSer: true });
    }

    deleteNewCreatedEmployee() {
        this.setState({ addNewUSer: false });
    }

    // addHandleLeadOptionChange = (sname, oValue) => {//need to remove as duplicate function
    //     let addNewUserData = this.state.addNewUserData;
    //     this.setState({ addNewUserData });
    //     addNewUserData[sname] = (oValue.id) != null ? oValue.id : oValue.target.value;
    //     this.setState({
    //         addNewUserData
    //     });
    // }

    addHandleLeadOptionChange = (sname, oValue) => {
        let addNewUserData = this.state.addNewUserData;
        let errors = { ...this.state.errors };
        addNewUserData[sname] = (oValue.id) != null ? oValue.id : oValue.target.value;
        if (oValue.id !== '') {
            delete errors[sname];
        } else {
            //errors[sname] = 'Please select role';
            errors["sname"] = this.props.t('dealerDetails.Basic_Employee_Details.user_type_required');
        }
        this.setState({
            errors,
            addNewUserData
        });
    }

    addHandleDateTimeChange = (date, sname) => {
        let addNewUserData = this.state.addNewUserData;
        let inputDate = new Date(date);
        addNewUserData[sname] = date;
        this.setState({ addNewUserData });
        addNewUserData[sname] = inputDate;
        this.setState({
            addNewUserData
        });
    }

    addHandleChangeEvent = event => {
        let addNewUserData = this.state.addNewUserData;
        let errors = { ...this.state.errors };
        addNewUserData[event.target.name] = event.target.value;
        if (event.target.value !== '') {
            delete errors[event.target.name];
        } else {
            errors[event.target.name] = 'Please enter ' + event.target.name;
        }
        if(event.target.name=='new_password')
            {
                if(event.target.value){
                    let regexp = PASSWORD_VALIDATION;
                    if (regexp.test(event.target.value))
                    {
                        delete errors[event.target.name];
                    }else{
                        errors[event.target.name] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.weak_password')
                    }
                }else{
                    delete errors[event.target.name];
                }
                
            }
        this.setState({
            addNewUserData,
            errors
        });
    }

    validForm = () => {
        let addNewUserData = this.state.addNewUserData;
        let formIsValid = true;
        let errors = { ...this.state.errors };
        if (!addNewUserData["name"]) {
            errors["name"] = this.props.t('dealerDetails.Basic_Employee_Details.name_required');
            formIsValid = false;
        }
        if (!addNewUserData["email"]) {
            formIsValid = false;
            errors["email"] = this.props.t('dealerDetails.Basic_Employee_Details.email_required');
        }else if (addNewUserData["email"] && !addNewUserData["email"].match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            formIsValid = false;
            errors["email"] = this.props.t('dealerDetails.Basic_Employee_Details.email_invalid');
        }
        if (!addNewUserData["mobile"]) {
            formIsValid = false;
           errors["mobile"] = this.props.t('dealerDetails.Basic_Employee_Details.mobile_required');
        }else if (!MOBILE_VALIDATION.test(addNewUserData["mobile"])) {
            formIsValid = false;
            errors["mobile"] = this.props.t('dealerDetails.Basic_Employee_Details.mobile_invalid');
        }
        if (!addNewUserData["user_type"]) {
            formIsValid = false;
            errors["user_type"] = this.props.t('dealerDetails.Basic_Employee_Details.user_type_required');
        }
 
        if(addNewUserData["new_password"] && addNewUserData["new_password"].match(PASSWORD_VALIDATION)==null){
            formIsValid = false;
            errors["new_password"] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.weak_password');
        }
        // if (addNewUserData["new_password"] && addNewUserData["new_password"].length <5) {
        //     formIsValid = false;
        //     errors["new_password"] = "Password should be greater than 4";
        // }

        this.setState({ errors: errors });
        return formIsValid;

    }

    handleLeadOptionChange = (sname, leadIndex, oValue) => {
        let getDealerEmployeeList = this.state.getDealerEmployeeList;
        this.setState({ getDealerEmployeeList });
        getDealerEmployeeList[leadIndex][sname] = (oValue.id) != null ? oValue.id : oValue.target.value;
        this.setState({
            getDealerEmployeeList
        });
    }

    handleDateTimeChange = (key, date, sname) => {
        let getDealerEmployeeList = this.state.getDealerEmployeeList;
        let inputDate = new Date(date);
        if (date == null) inputDate = null;
        getDealerEmployeeList[key][sname] = date;
        this.setState({ getDealerEmployeeList });
        getDealerEmployeeList[key][sname] = inputDate;
        this.setState({
            getDealerEmployeeList
        });
    }

    handleChangeEvent = event => {
        let index = event.target.attributes.getNamedItem('dataindex').value;
        let getDealerEmployeeList = this.state.getDealerEmployeeList;
        let errors = { ...this.state.errors };
        getDealerEmployeeList[index][event.target.name] = event.target.value;
        if (event.target.value !== '') {
            delete errors[event.target.name + index];
        } else {
            errors[event.target.name + index] = 'Please enter ' + event.target.name;
        }
        if(event.target.name=='new_password')
            {
                if(event.target.value){
                    let regexp = PASSWORD_VALIDATION;
                    if (regexp.test(event.target.value))
                    {
                        delete errors[event.target.name + index];
                    }else{
                        errors[event.target.name + index] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.weak_password')
                    }
                }else{
                    delete errors[event.target.name + index];
                }
                
            }
        this.setState({
            getDealerEmployeeList,
            errors
        });
    }

    validUpdateForm = (index) => {
        let getDealerEmployeeList = this.state.getDealerEmployeeList;
        let formIsValid = true;
        let errors = { ...this.state.errors };
        if (!getDealerEmployeeList[index]["name"]) {
            //errors['name' + index] = "Please enter name";
            errors['name' + index] = this.props.t('dealerDetails.Basic_Employee_Details.name_required')
            formIsValid = false;
        }
        if (!getDealerEmployeeList[index]["email"]) {
            formIsValid = false;
           // errors["email" + index] = "Please enter email";
           errors['email' + index] = this.props.t('dealerDetails.Basic_Employee_Details.email_required')
        }
        if (getDealerEmployeeList[index]["email"] && !getDealerEmployeeList[index]["email"].match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            formIsValid = false;
            //errors["email" + index] = "Invalid email ID.";
            errors['email' + index] = this.props.t('dealerDetails.Basic_Employee_Details.email_invalid')
        }
        if (!getDealerEmployeeList[index]["mobile"]) {
            formIsValid = false;
            //errors["mobile" + index] = "Please enter mobile.";
            errors['mobile' + index] = this.props.t('dealerDetails.Basic_Employee_Details.mobile_required')
        }else if (getDealerEmployeeList[index]["mobile"] && !MOBILE_VALIDATION.test(getDealerEmployeeList[index]["mobile"])) {
            formIsValid = false;
            //errors["mobile" + index] = "Invalid Dealership contact no.";
            errors['mobile' + index] = this.props.t('dealerDetails.Basic_Employee_Details.mobile_invalid')
        }
        if (!getDealerEmployeeList[index]["user_type"]) {
            formIsValid = false;
            //errors["user_type" + index] = "Please select role.";
            errors['user_type' + index] = this.props.t('dealerDetails.Basic_Employee_Details.user_type_required')
        }
        if(getDealerEmployeeList[index]["new_password"] && getDealerEmployeeList[index]["new_password"].match(PASSWORD_VALIDATION)==null){
            formIsValid = false;
            errors["password"] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.weak_password');
        }
        
       
        this.setState({ errors: errors });

        return formIsValid;
    }




    render() {
        const dobMaxDate = new Date();
        dobMaxDate.setFullYear(dobMaxDate.getFullYear() - 15);
        // const { addNewUSer, getDealerEmployeeList, roleList, addNewUserData, passwordDesibled, errors } = this.state;//need to remove
        const { addNewUSer, getDealerEmployeeList, roleList, addNewUserData, errors } = this.state;
        // console.log(errors, 'errorr')
        const { basic_details } = this.props;
        const { role_access } = this.state;
        if (this.state.redirect) {
            return (<Redirect to={'/login'} />);
        }
        return (
            <div className={(this.state.loading) ? "loading" : ""}>
                {/* <PageLoader loaderClass="loader content-loader"/> */}
                {
                    role_access.includes("add_employee") ? <button className="btn btn-success pull-right " onClick={this.addNewDelearEmployee}>{this.props.t('dealerDetails.Basic_Employee_Details.Adduser')}</button>: ''
                }
                <h1>{this.props.t('dealerDetails.Basic_Employee_Details.Employee_Details')} <span className="f12">
                    {(basic_details.gcd_code) ? <span> ({basic_details.organization} - {basic_details.gcd_code})</span> : ''}</span>
                    <span className="f12">
                        {
                            (basic_details.vendor_id) ? <span> Vendor ID - {basic_details.vendor_id}</span> : ''
                        }
                    </span>
                </h1>
                <div className="card os-animation animated fadeInDown" data-os-animation="fadeInDown" data-os-animation-delay="0s">
                    <div className="card-body accrdian-bt0">
                        {addNewUSer ?
                            <Accordion title={this.props.t('dealerDetails.Basic_Employee_Details.Adduser')} active="true" delete="true">

                                <button className="btn btn-link delete-user" onClick={() => { if (window.confirm(this.props.t('dealerDetails.Basic_Employee_Details.Confirm_Delete'))) this.deleteNewCreatedEmployee() }} ><i className="ic-delete"></i>{this.props.t('dealerDetails.Basic_Employee_Details.Delete')}</button>

                                <div >
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <InputField
                                                inputProps={{
                                                    id: "name",
                                                    type: "text",
                                                    name: "name",
                                                    label: this.props.t('dealerDetails.Basic_Employee_Details.Name'),
                                                    label_before_input: "true",
                                                    dataerror: errors.name || '',
                                                    // validationreq: "required"//need to remove
                                                    validationreq: "true"
                                                }}
                                                onChange={this.addHandleChangeEvent}
                                            />
                                        </div>
                                        <div className="col-sm-4">
                                            <InputField
                                                inputProps={{
                                                    id: "email",
                                                    type: "text",
                                                    name: "email",
                                                    label: this.props.t('dealerDetails.Basic_Employee_Details.Email'),
                                                    label_before_input: "true",
                                                    dataerror: errors.email || '',
                                                    // validationreq: "required, email"//need to remove
                                                    validationreq: "true"
                                                }}
                                                onChange={this.addHandleChangeEvent}
                                            />
                                        </div>
                                        {/* <div className="col-sm-4">
                                            <InputField
                                                inputProps={{
                                                    id: "new_password",
                                                    type: "password",
                                                    name: "new_password",
                                                    label: this.props.t('dealerDetails.Basic_Employee_Details.New_Password'),
                                                    label_before_input: "true",
                                                    dataerror: errors.new_password || '',
                                                }}
                                                onChange={this.addHandleChangeEvent}
                                            />
                                        </div> */}
                                        {/* <div className="col-sm-4">
                                            <label>{this.props.t('dealerDetails.Basic_Employee_Details.Date_Of_Birth')}</label>
                                            <div>
                                                <DatePicker
                                                    showYearDropdown 
                                                    showMonthDropdown
                                                    selected={addNewUserData.birth_date}
                                                    onChange={(date) => this.addHandleDateTimeChange(date, 'birth_date')}
                                                    name="birth_date"
                                                    id="birth_date"
                                                    autocompleate="off"
                                                    placeholderText={this.props.t('dealerDetails.Basic_Employee_Details.Date_Of_Birth')}
                                                   //dateFormat="yyyy-MM-dd"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    maxDate={dobMaxDate}
                                                    />
                                            </div>
                                        </div> */}
                                        <div className="col-sm-4">
                                            <InputField
                                                inputProps={{
                                                    id: "mobile",
                                                    type: "tel",
                                                    name: "mobile",
                                                    label: this.props.t('dealerDetails.Basic_Employee_Details.Mobile'),
                                                    label_before_input: "true",
                                                    dataerror: errors.mobile || '',
                                                    // validationreq: "required",//need to remove
                                                    validationreq: "true",
                                                    maxLength: 12
                                                }}
                                                onChange={this.addHandleChangeEvent}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <label>{this.props.t('dealerDetails.Basic_Employee_Details.Role')}<span className="required" style={{ color: 'red' }}>*</span></label>
                                            <Select
                                                components={{ IndicatorSeparator:() => null }}
                                                id="user_type"
                                                options={roleList}
                                                onChange={this.addHandleLeadOptionChange.bind(this, 'user_type')}
                                                name="user_type"
                                                placeholder={this.props.t('dealerDetails.Basic_Employee_Details.Select_Role')}
                                                value={roleList.filter(({ id }) => id === addNewUserData.user_type)}
                                                getOptionLabel={({ name }) => name}
                                                getOptionValue={({ id }) => id}
                                            />
                                            <span className="error show">{(errors.user_type) ? errors.user_type : ''}</span>
                                        </div>
                                        {/* <div className="col-sm-4">
                                            <label>{this.props.t('dealerDetails.Basic_Employee_Details.Date_Of_Joining')}</label>
                                            <div>
                                                <DatePicker
                                                    showYearDropdown 
                                                    showMonthDropdown
                                                    selected={addNewUserData.date_of_joining}
                                                    onChange={(date) => this.addHandleDateTimeChange(date, 'date_of_joining')}
                                                    name="date_of_joining"
                                                    id="date_of_joining"
                                                    autocompleate="off"
                                                    placeholderText={this.props.t('dealerDetails.Basic_Employee_Details.Date_Of_Joining')}
                                                    // dateFormat="yyyy-MM-dd"
                                                    autoComplete="off"
                                                    className="form-control"

                                                />
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="row text-center">
                                        {
                                            role_access.includes("add_employee") ? <button onClick={this.addDealerEmployeeDetails} className="btn btn-primary " >{this.props.t('dealerDetails.Basic_Employee_Details.Save')}</button>: ''
                                        }
                                    </div>
                                </div>
                            </Accordion>
                            : ''}


                        {/* ////////////////////////////////Listing Emplooyee date//////////////////////////////////// */}


                        {getDealerEmployeeList.length > 0 ?
                            getDealerEmployeeList.map((employeeData, index) =>
                                <Accordion key={employeeData.id} title={employeeData.name + ' Role : ' + employeeData.user_type_name + ' Contact No. ' + employeeData.mobile}>
                                    <div className="row">
                                        <div className="col-sm-12 text-right">
                                            {
                                                role_access.includes("add_employee") ? <button className="btn btn-link pull-right" onClick={() => { if (window.confirm(this.props.t('dealerDetails.Basic_Employee_Details.Confirm_Delete'))) this.deleteEmployeeDetails(employeeData.id) }} ><i className="ic-delete"></i> {this.props.t('dealerDetails.Basic_Employee_Details.Delete')}</button>: ''
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <InputField
                                                    inputProps={{
                                                        id: 'name' + index,
                                                        dataindex: index,
                                                        type: "text",
                                                        name: "name",
                                                        label: this.props.t('dealerDetails.Basic_Employee_Details.Name'),
                                                        label_before_input: "true",
                                                        value: employeeData.name,
                                                        dataerror: errors['name' + index] || '',
                                                        // validationreq: "required"//need to remove
                                                        validationreq: "true"
                                                    }}
                                                    onChange={this.handleChangeEvent}
                                                />
                                            </div>
                                            <div className="col-sm-4">
                                                <InputField
                                                    inputProps={{
                                                        id: "email" + index,
                                                        dataindex: index,
                                                        type: "text",
                                                        name: "email",
                                                        label: this.props.t('dealerDetails.Basic_Employee_Details.Email'),
                                                        label_before_input: "true",
                                                        value: employeeData.email,
                                                        dataerror: errors['email' + index] || '',
                                                        // validationreq: "required, email"//need to remove
                                                        validationreq: "true"
                                                    }}
                                                    onChange={this.handleChangeEvent}
                                                />
                                            </div>
                                            {/* <div className="col-sm-4 edit-mobile">
                                                {passwordDesibled == 'true' ?
                                                    <div>
                                                        <InputField
                                                            inputProps={{
                                                                id: "new_password" + index,
                                                                dataindex: index,
                                                                type: "password",
                                                                name: "new_password",
                                                                label: this.props.t('dealerDetails.Basic_Employee_Details.New_Password'),
                                                                readOnly: "readOnly",
                                                                desibled: 'desibled',
                                                                label_before_input: "true",
                                                                dataerror: errors['new_password' + index] || ''
                                                            }}
                                                            onChange={this.handleChangeEvent}
                                                        />
                                                        <a className="edit-mobile-link" data-flag="false" data-index={index} onClick={this.newPasswordEnable}>{this.props.t('dealerDetails.Basic_Details_master.Enable_New_Password')}</a>
                                                    </div>
                                                    :
                                                    <div>
                                                        <InputField
                                                            inputProps={{
                                                                id: "new_password" + index,
                                                                dataindex: index,
                                                                type: "password",
                                                                name: "new_password",
                                                                label: this.props.t('dealerDetails.Basic_Employee_Details.New_Password'),
                                                                value: employeeData.new_password,
                                                                label_before_input: "true",
                                                                dataerror: errors['new_password' + index] || '',
                                                            }}
                                                            onChange={this.handleChangeEvent}
                                                        />
                                                        <a className="edit-mobile-link" data-flag="true" data-index={index} onClick={this.newPasswordEnable}>{this.props.t('dealerDetails.Basic_Details_master.Disabled_New_Password')}</a>
                                                    </div>
                                                }
                                            </div> */}
                                             {/* <div className="col-sm-4">
                                                <label> {this.props.t('dealerDetails.Basic_Employee_Details.Date_Of_Birth')} </label>
                                                <div>
                                                    <DatePicker
                                                        showYearDropdown 
                                                        showMonthDropdown
                                                        selected={(employeeData.birth_date) ? new Date(employeeData.birth_date) : ''}
                                                        onChange={(date) => this.handleDateTimeChange(index, date, 'birth_date')}
                                                        name="birth_date"
                                                        id="birth_date"
                                                        autocompleate="off"
                                                        placeholderText={this.props.t('dealerDetails.Basic_Employee_Details.Date_Of_Birth')}
                                                        //dateFormat="yyyy-MM-dd"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        maxDate={dobMaxDate}

                                                    />
                                                </div>
                                            </div> */}
                                             <div className="col-sm-4">
                                                <InputField
                                                    inputProps={{
                                                        id: "mobile" + index,
                                                        dataindex: index,
                                                        type: "tel",
                                                        name: "mobile",
                                                        label: this.props.t('dealerDetails.Basic_Employee_Details.Mobile'),
                                                        label_before_input: "true",
                                                        value: employeeData.mobile,
                                                        dataerror: errors['mobile' + index] || '',
                                                        // validationreq: "required",//need to remove
                                                        validationreq: "true",
                                                        maxLength: 12
                                                    }}
                                                    onChange={this.handleChangeEvent}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                           
                                            <div className="col-sm-4">
                                                <label>{this.props.t('dealerDetails.Basic_Employee_Details.Role')}<span className="required" style={{ color: 'red' }}>*</span></label>
                                                <Select
                                                    components={{ IndicatorSeparator:() => null }}
                                                    id="user_type"
                                                    options={roleList}
                                                    onChange={this.handleLeadOptionChange.bind(this, 'user_type', index)}
                                                    name="user_type"
                                                    placeholder={this.props.t('dealerDetails.Basic_Employee_Details.Select_Role')}
                                                    value={roleList.filter(({ id }) => id === employeeData.user_type)}
                                                    getOptionLabel={({ name }) => name}
                                                    getOptionValue={({ id }) => id}
                                                />
                                                <p className="error">{(errors['user_type' + index]) ? errors['user_type' + index] : ''}</p>
                                            </div>
                                            {/* <div className="col-sm-4">
                                                <label>{this.props.t('dealerDetails.Basic_Employee_Details.Date_Of_Joining')}</label>
                                                <div>
                                                    <DatePicker
                                                        showYearDropdown 
                                                        showMonthDropdown
                                                        selected={(employeeData.date_of_joining) ? new Date(employeeData.date_of_joining) : ''}
                                                        onChange={(date) => this.handleDateTimeChange(index, date, 'date_of_joining')}
                                                        name="date_of_joining"
                                                        id="date_of_joining"
                                                        autocompleate="off"
                                                        placeholderText={this.props.t('dealerDetails.Basic_Employee_Details.Date_Of_Joining')}
                                                        //dateFormat="yyyy-MM-dd"
                                                        autoComplete="off"
                                                        className="form-control"

                                                    />
                                                </div>
                                            </div> */}
                                        </div>
                                        {/* <div className="row">
                                            <div className="col-sm-4">
                                                <label>{this.props.t('dealerDetails.Basic_Employee_Details.Date_Of_Joining')}</label>
                                                <div>
                                                    <DatePicker
                                                        showYearDropdown 
                                                        showMonthDropdown
                                                        selected={(employeeData.date_of_joining) ? new Date(employeeData.date_of_joining) : ''}
                                                        onChange={(date) => this.handleDateTimeChange(index, date, 'date_of_joining')}
                                                        name="date_of_joining"
                                                        id="date_of_joining"
                                                        autocompleate="off"
                                                        placeholderText={this.props.t('dealerDetails.Basic_Employee_Details.Date_Of_Joining')}
                                                        //dateFormat="yyyy-MM-dd"
                                                        autoComplete="off"
                                                        className="form-control"

                                                    />
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="row text-center">
                                            {
                                                role_access.includes("update_employee") ? <button className="btn btn-primary" data-index={index} onClick={this.updateDealerEmployeeDetails}>{this.props.t('dealerDetails.Basic_Employee_Details.Update')}</button>: ''
                                            }
                                        </div>
                                    </div>
                                </Accordion>
                            ) : ''}
                    </div>

                    <div className="card-footer text-center">
                        {/* <button className="btn btn-default mrg-r30" ><i className="sprite ic-prev-b"></i> {this.props.t('dealerDetails.Basic_Employee_Details.Previous')}</button> */}
                        <NavLink to={this.props.prevpage} className="btn btn-default mrg-r30">{this.props.t('dealerDetails.Outlet_Details_master.Previous')} <i className="ic-arrow_back"></i></NavLink>
                        <NavLink to={this.props.nextpage} className="btn btn-primary">{this.props.t('dealerDetails.Outlet_Details_master.Next')} <i className="ic-arrow_forward"></i></NavLink>
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(EmployeeDetails));
