import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ModalPopup from '../elements/Popup';
import MasterService from '../../service/MasterService';
import { DealerService } from '../../service';
import { toast } from 'react-toastify';
import Button from '../elements/Button';
import secureStorage from '../../config/encrypt';
class SearchResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dealerListData: props.dealerListData,
      loading: false,
      pageloading: false,
      statusClassname: {
        active: 'nav-item active',
        deleted: 'nav-item',
      },
      filterData: {},
      dealerUpdateData: {},
      blackListPopup: false,
      blackListUpdateData: {},
      inactiveReasonPopup: false,
      inactiveReasonUpdateData: {},
      currentIndex: null,
      current_org_name: null,
      historyPopup: false,
      histoy_dealer_id: 0,
      export_access: false,
      delete_user: false,      
      dealer_id_hash:0
    };
  }

  closeHistoryModel = () => {
    this.setState({ historyPopup: false });
  };

  handleHistory = (id) => {
    this.setState({ historyPopup: true });
    this.setState({ histoy_dealer_id: id });
  };

  componentDidMount = async () => {
    let self = this;
    if (secureStorage.getItem('loginUserInfo')) {
      let loginResponse = secureStorage.getItem('loginUserInfo');
      if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
        let export_access = loginResponse.data.user_data.export_access ? loginResponse.data.user_data.export_access : 0;
        if (export_access) {
          self.setState({ export_access: true });
        }
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      dealerListData: nextProps.dealerListData,
      loading: nextProps.loading,
      pageloading: nextProps.pageloading,
    });
  }
  handleStatusClick = (sname, ovalue) => {
    let filterData = this.state.filterData;
    if (sname == 'active') {
      filterData['status'] = 1;
    } else if (sname == 'deleted') {
      filterData['status'] = 0;
    }
    this.setState({ filterData });
    if (typeof this.props.statusClick === 'function') {
      this.props.statusClick(sname, ovalue);
      this.setState({
        statusClassname: {
          active: 'nav-item',
          deleted: 'nav-item',
          [sname]: 'nav-item active',
        },
      });
    }
  };

  getBasicDetails() {
    return new Promise((resolve, reject) => {
      DealerService.getMasters(['dealer_sku'])
        .then((response) => {
          if (response.status == 200 && response.data.status == 200) {
            let responsedata = response.data.data || {};
            if (responsedata.dealer_sku && responsedata.dealer_sku.length) {
              let subscM = [];
              // eslint-disable-next-line array-callback-return
              responsedata.dealer_sku.map((v) => {
                if (v.sku_type == 'subscription') subscM.push(v);
              });
              resolve(subscM);
            }
          } else {
            reject(response.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deleteUser = (dealer_id_hash) => {
    this.props.pageLoadingActivate(true)
    this.setState({ delete_user: false });
    let self = this;
    if (dealer_id_hash != '') {
      MasterService.post('dealer/dealer/delete_sales_user', {
        sales_id_hash:dealer_id_hash,
      })
        .then(function (response) {
          if (response.data.status == 200) {
            self.props.salesuserlist()
            self.props.salesusercount()
            toast.success(response.data.message);
            this.props.pageLoadingActivate(false)
          }
        })
        .catch(function (error) {
          self.props.pageLoadingActivate(false)
          self.setState({ delete_user: false });
          console.log(error.message);
        });
    } else {
      self.props.pageLoadingActivate(false)
      self.setState({ delete_user: false});
    }
  };

  closeInactiveReasonModal = () => {
    this.setState({ inactiveReasonPopup: false });
  };

  showConfirmModal = (dealer_id_hash) => {
    this.setState({ delete_user: true,dealer_id_hash });
  };
  closeConfirmModal = () => {
    this.setState({ delete_user: false });
  };

  render() {
    const { dealerCountStatusDetail } = this.props;
    let role_access = [];
    if (secureStorage.getItem('loginUserInfo')) {
      let loginResponse = secureStorage.getItem('loginUserInfo');
      role_access =
        loginResponse &&
        loginResponse.data &&
        loginResponse.data.user_data &&
        loginResponse.data.user_data.role_data &&
        loginResponse.data.user_data.role_data.length > 0
          ? loginResponse.data.user_data.role_data
          : [];
    }

    return (
      <div className={this.state.loading ? 'loading result-wrap' : 'result-wrap'}>
        <div className="card">
          <div className="pad-15">
            <div className="row">
              <div className="col-sm-8">
                <ul className="list-inline nav-tabs">
                  <li className={this.state.statusClassname.active} onClick={this.handleStatusClick.bind(this, 'active')}>
                    <div>
                      {this.props.t('Listing.Search_Result.Active')}
                      <span>({dealerCountStatusDetail.active})</span>
                    </div>
                  </li>
                  <li className={this.state.statusClassname.deleted} onClick={this.handleStatusClick.bind(this, 'deleted')}>
                    <div>
                    {this.props.t('salessupportuserDetails.Basic_Details_master.Deleted')}
                      <span>({dealerCountStatusDetail.deleted})</span>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-sm-4 text-right pad-t10">
              {
                  role_access.includes("sales_user_save") && this.state.filterData && this.state.filterData.status != 0 ? <NavLink to="/sales-user/basic-details" className="btn btn-success">
                  {this.props.t('salessupportuserDetails.Basic_Details_master.new_backend_user')}
                </NavLink> :''
              }
              </div>
            </div>
          </div>
          <div className="clearfix">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr className="dealer-listing">
                    <th className="nowrap">{this.props.t('salessupportuserDetails.Basic_Details_master.name')}</th>
                    <th className="nowrap">{this.props.t('salessupportuserDetails.Basic_Details_master.email')}</th>
                    <th className="nowrap">{this.props.t('salessupportuserDetails.Basic_Details_master.role')}</th>
                    <th className="nowrap">{ this.state.filterData && this.state.filterData.status == 0 ? this.props.t('salessupportuserDetails.Basic_Details_master.deleted_date') : this.props.t('salessupportuserDetails.Basic_Details_master.created_date') }</th>
                    <th className="nowrap">{ this.state.filterData && this.state.filterData.status == 0 ? this.props.t('salessupportuserDetails.Basic_Details_master.deleted_by') : this.props.t('salessupportuserDetails.Basic_Details_master.created_by') }</th>
                    {
                      this.state.filterData.status != 0 ? <th>{this.props.t('salessupportuserDetails.Basic_Details_master.action')}</th> : ''
                    }
                    
                  </tr>
                </thead>
                <tbody>
                  {this.state.dealerListData.length == 0 ? (
                    <tr>
                      <td align="center" colSpan="7">
                        <h6 className="text-center text-danger text-bold">{this.props.t('Listing.Search_Result.No_Record_Found')}</h6>
                      </td>
                    </tr>
                  ) : null}
                  {this.state.dealerListData.length != 0 &&
                    this.state.dealerListData.map((dealerdata, k) => (
                      <tr key={k}>
                        <td  className="nowrap">{dealerdata.name}</td>
                        <td>{dealerdata.email}</td>
                        <td>{dealerdata.role}</td>
                        <td>{dealerdata.status == '1' ? dealerdata.created_date : dealerdata.updated_date}</td>
                        <td>{dealerdata.created_by}</td>
                        {
                          dealerdata.status == '1'?  <td>
                          <div className="btn-group btn-group-small mrg-r10">
                          {
                              role_access.includes("sales_user_edit") ? dealerdata.status == '1' ? <Link to={`/sales-user/${dealerdata.dealer_id_hash}/basic-details`} className="btn btn-default">
                              <i className="ic-createmode_editedit icons-normal"></i>
                            </Link> :'':''
                          }

                          {
                              role_access.includes("sales_user_delete") ? dealerdata.status == '1' ? <button
                              className="btn btn-default"
                              type="button"
                              title="Delete Category"
                              onClick={(e) => {
                                this.showConfirmModal(dealerdata.dealer_id_hash);
                              }}
                            >
                              <i className="ic-delete icons-normal"></i>
                            </button> :'':''
                          }
                          </div>
                        </td> : ''
                        }
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {this.state.delete_user === false ? null : (
          <ModalPopup
            className="modal"
            title={this.props.t('salessupportuserDetails.Basic_Employee_Details.CONFIRM')}
            modalClose={this.closeConfirmModal}
          >
            <div>
              <p>{this.props.t('salessupportuserDetails.Basic_Employee_Details.Confirm_Delete_user')} </p>
              <div className="col-sm-6 col-md-12 form-group text-right">
                <Button
                  type="button"
                  colclass="col-sm-12"
                  onClick={this.closeConfirmModal}
                  btnClass="btn btn-primary"
                  name="closeconfirm"
                  id="closeconfirm"
                  title={this.props.t('salessupportuserDetails.Basic_Employee_Details.no')}
                />
                &nbsp;&nbsp;
                <Button
                  type="button"
                  colclass="col-sm-12"
                  onClick={() => {
                    this.deleteUser(this.state.dealer_id_hash);
                  }}
                  btnClass="btn btn-success"
                  name="ok"
                  id="ok"
                  title={this.props.t('salessupportuserDetails.Basic_Employee_Details.yes')}
                />
              </div>
            </div>
          </ModalPopup>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData }),
  };
};
export default withTranslation('sales_support_users')(connect(mapStateToProps, mapDispatchToProps)(SearchResult));
