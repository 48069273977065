import React, { Component } from "react";
import InputField from "../../elements/Input";
import Button from "../../elements/Button";
import MasterService from "../../../service/MasterService";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Select from "react-select";
import secureStorage from './../../../config/encrypt';
import { NumericFormat as NumberFormat } from "react-number-format";

class IFCategoryManagement extends Component {
    constructor(props) {
        super(props);
        console.log(props, 'test')
        this.state = {
            adminUserData: props.adminUserData,
            isEdit: props.isEdit,
            adminFeeType: [{
                value:1,
                label:"Percentage Value"
            },{
                value:2,
                label:"Flat Numeric Value"
            }],
            adminFeeTypeSuffix: '%',
            formData: props.formData || {},
            errors: {},
            loading: false
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            adminUserData: nextProps.adminUserData,
            isEdit: nextProps.isEdit,
            formData: nextProps.formData,
        });
    }

    handleInputField = (event, keyName) => {
        let formData = this.state.formData;
        let errors = this.state.errors;
        if(keyName == 'category_name'){
            formData[keyName] = event.target.value;
        }else if(keyName == 'renewal_time' || keyName == 'loan_tenure'){//
            const validated = event.target.value.match(/^[0-9]+$/)
            if(validated){
                formData[keyName] = event.target.value;
            }else{
                formData[keyName] = 0;
            }
        }else if(keyName === 'penalty_fee'){
            const validated = event.value.match(/^(?!0(\.0+)?$)\d*(\.\d{0,2})?$/);
            formData[keyName] = validated ? event.value : '';
        } else{
            const validated = event.target.value.match(/^(\d*\.{0,1}\d{0,2}$)/)
            if(validated){
                formData[keyName] = event.target.value;
            } 
        }
        delete errors[keyName];
        this.setState({ errors})

    };

    handleChange = (data, name) => {
        let formData = this.state.formData;
        let errors = this.state.errors;
        formData[name] = data.value;
        if (data && data.value === 1) {
            this.setState({ adminFeeTypeSuffix: '%' })
        } else {
            this.setState({ adminFeeTypeSuffix: '' })
        }
        delete errors[name];
        this.setState({ formData })
        this.setState({ errors})

    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true })
        if (this.validForm()) {
        MasterService.post("/dealer/inventory-funding/save_if_category_list", {
            ...this.state.formData,
            ltv:this.state.formData.ltv == '' ? null : this.state.formData.ltv,
            created_by: this.state.adminUserData.user_id,
            updated_by: this.state.adminUserData.user_id,
        })
            .then((response) => {
                if (response && response.data && response.data.status === 200) {
                    toast.success("Data saved successfully");
                    this.handleModelClose();
                    this.setState({ loading: false })
                } else {
                    this.setState({ loading: false })
                    toast.error(response.data.message);
                }
            })
            .catch((error) => {
                this.handleModelClose();
                this.setState({ loading: false })
                toast.error(error);
            });
        }else{
            this.setState({ loading: false })
        }
    };

    validForm = () => {
        let fields = this.state.formData;
         let formIsValid = true;
         let errors = { ...this.state.errors };
        if (!fields["category_name"]) {
            errors['category_name'] =   this.props.t('Listing.IF_VALIDATION.Category_Name');
            formIsValid = false;
        }
        if (!fields["interest_fee"]) {
            errors['interest_fee'] =   this.props.t('Listing.IF_VALIDATION.Interest_Fee');
            formIsValid = false;
        }
        if (!fields["provision_fee"]) {
            errors['provision_fee'] =   this.props.t('Listing.IF_VALIDATION.Provision_Fee');
            formIsValid = false;
        }
        if (!fields["admin_fee"]) {
            errors['admin_fee'] =   this.props.t('Listing.IF_VALIDATION.Admin_Fee');
            formIsValid = false;
        }
        if (!fields["renewal_fee"]) {
            errors['renewal_fee'] =   this.props.t('Listing.IF_VALIDATION.Renewal_Fee');
            formIsValid = false;
        }
        if (!fields["renewal_time"]) {
            errors['renewal_time'] =   this.props.t('Listing.IF_VALIDATION.Renewal_Time');
            formIsValid = false;
        }
        if (!fields["loan_tenure"]) {
            errors['loan_tenure'] =   this.props.t('Listing.IF_VALIDATION.Loan_Tenure');
            formIsValid = false;
        }
        if (!fields["penalty_fee"]) {
            errors['penalty_fee'] =   this.props.t('Listing.IF_VALIDATION.Penalty_Fee');
            formIsValid = false;
        }
        if (!fields["penalty_interest"]) {
            errors['penalty_interest'] =   this.props.t('Listing.IF_VALIDATION.Penalty_Interest');
            formIsValid = false;
        }

        if (fields["admin_fee"] != '' && fields["admin_fee_type"] == 1) {
            if(fields["admin_fee"] > 100){
                errors['admin_fee'] =  this.props.t('Listing.IF_VALIDATION.Admin_Fee_Type');
                formIsValid = false;
            }
        }

        if (fields["admin_fee"] != '' && fields["admin_fee_type"] == 2) {
            if(fields["admin_fee"] > 99999999){
                errors['admin_fee'] =  this.props.t('Listing.IF_VALIDATION.Admin_Fee_val');
                formIsValid = false;
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleModelClose = () => {
        if (this.state.isEdit) {
            this.props.closeIfEditCategoryModel();
        } else {
            this.props.closeIfAddCategoryModel();
        }
    };

    render() {
        const { formData, errors } = this.state;
        formData['admin_fee_type'] = this.state.formData.admin_fee_type ? this.state.formData.admin_fee_type: 1
        // let css = "";//need to remove below three lines as var css not in use
        // if (errors) {
        //     css = `.form-error {display:block}`;
        // }
        let role_access = []
        if (secureStorage.getItem('loginUserInfo')) {
            let loginResponse = secureStorage.getItem('loginUserInfo');
            role_access = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role_data && loginResponse.data.user_data.role_data.length > 0 ? loginResponse.data.user_data.role_data : []
        }

        return (
            <div className={this.state.loading ? "container-fluid loading" : "container-fluid"}>
                <form className="text-left os-animation animated fadeInDown">
                    <div className="row form-group">
                        <div className="col-sm-4 ">
                            <InputField
                                type="text"
                                error={""}
                                name="category_name"     
                                id="category_name"
                                placeholder={this.props.t("Listing.IF_Add_Category.Category_Name")}
                                value={this.state.formData.category_name}
                                label={this.props.t("Listing.IF_Add_Category.Category_Name")}
                                maxLength={40}
                                onChange={(e) => {
                                    this.handleInputField(e, 'category_name');
                                }}
                                required={true}
                                
                            />
                            {
                                (errors && errors.category_name) ? <span className="error show" style={{"marginTop": "-13px","lineHeight": "1px"}}>{errors.category_name}</span> : ''
                            }
                        </div>
                    </div>
                    <div className="row">
                        {[
                            { key: "Interest_Fee", type: "number", suffix: '%', min: 0, max: 100 },
                            { key: "Provision_Fee", type: "number", suffix: '%', min: 0, max: 100 },
                            { key: "Admin_Fee_Type", type: "select", suffix: '' },
                            { key: "Admin_Fee", type: "number", suffix: this.state.adminFeeTypeSuffix },
                            { key: "Renewal_Fee", type: "number", suffix: '%', min: 0, max: 100 },
                            { key: "Renewal_Time", type: "text", suffix: 'Days', max: 1000000 },
                            { key: "Loan_Tenure", type: "text", suffix: 'Days', max: 1000000 },
                            { key: "Penalty_Interest", type: "number", suffix: '%', min: 0, max: 100 },
                            { key: "Penalty_Fee", type: "number", suffix: 'Per Day', min: 0, max: 10000000000 },
                            { key: "ltv", type: "number", suffix: '%', min: 0, max: 100 }
                        ].map((field, index) => {
                            const lowerCaseKey = field.key.toLowerCase();
                            const translatedPlaceholder = this.props.t(`Listing.IF_Add_Category.${field.key}`);
                            if (['Penalty_Fee'].includes(field.key)) {
                                return (<div className="col-sm-3 mrg-b5" key={index}>
                                    <label>{`${translatedPlaceholder} ${field.suffix ? `(${field.suffix})` : ''}`}<span className="required" style={{ color: 'red', marginLeft: "4px" }}>*</span></label>
                                    <NumberFormat
                                        className="form-control"
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        type="text"
                                        name={lowerCaseKey}
                                        id={lowerCaseKey}
                                        placeholder={translatedPlaceholder}
                                        value={parseFloat(this.state.formData[lowerCaseKey]) || ""}
                                        label={`${translatedPlaceholder} ${field.suffix ? `(${field.suffix})` : ''}`}
                                        onValueChange={(e) => {
                                            this.handleInputField(e, lowerCaseKey);
                                        }}
                                    />
                                    {
                                        (errors && errors[lowerCaseKey]) ? <span className="error show">{errors[lowerCaseKey]}</span> : ''
                                    }
                                </div>)
                            } else if (field.key === 'Admin_Fee_Type') {
                                return (<div className="col-sm-3 mrg-b5" key={index}>
                                    <label>{translatedPlaceholder}</label>
                                    <Select
                                        components={{ IndicatorSeparator:() => null }}
                                        name={lowerCaseKey}
                                        id={lowerCaseKey}
                                        options={this.state.adminFeeType}
                                        onChange={(data) => {
                                            this.handleChange(data, lowerCaseKey);
                                        }}
                                        placeholder={translatedPlaceholder}
                                        value={this.state.adminFeeType.filter(({ value }) => value == this.state.formData[lowerCaseKey])}
                                        getOptionLabel={({ label }) => label}
                                        getOptionValue={({ value }) => value}
                                    />
                                </div>)
                            } else {
                                return (<div className="col-sm-3 mrg-b5" key={index}>
                                    <InputField
                                        min={field.min}
                                        max={field.max}
                                        type={field.type}
                                        maxLength={field.max}
                                        error={""}
                                        name={lowerCaseKey}
                                        id={lowerCaseKey}
                                        placeholder={translatedPlaceholder}
                                        value={this.state.formData[lowerCaseKey] || ""}
                                        label={`${translatedPlaceholder} ${field.suffix ? `(${field.suffix})` : ''}`}
                                        onChange={(e) => {
                                            this.handleInputField(e, lowerCaseKey);
                                        }}
                                        required= {['ltv'].includes(field.key) ? false: true}
                                    />
                                    {
                                        (errors && errors[lowerCaseKey]) ? <span className="error show" style={{ "marginTop": "-13px", "lineHeight": "1px" }}>{errors[lowerCaseKey]}</span> : ''
                                    }
                                </div>)
                            }

                        })}
                    </div>

                    <div className="row">
                        <div className="col-sm-6 col-md-12 form-group text-center">
                            {
                               role_access.includes("inventory_funding_category_save") &&
                                <Button
                                    onClick={this.handleSubmit}
                                    colclass="col-sm-12"
                                    btnClass="btn btn-primary mrg-r15"
                                    type="submit"
                                    name="addleadbtn"
                                    id="cancel"
                                    title={this.props.t("Listing.IF_Add_Category.Save_Details")}
                                />
                            }
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id,
    };
};

export default withTranslation("inventory_funding")(connect(mapStateToProps)(IFCategoryManagement));
