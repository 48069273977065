import ActionTypes from './../action/action';
const initialState = {
    userLoggedIn: 0,
    userData: {},
    dealerData: {},
    authToken: '',
    dealer_id: '',
    name: ''
}
// export default (state = initialState, action) => {//need to remove added one more line at last i.e export default defaultReducer;
const defaultReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CHANGE_CONTENT:
            return ({
                ...state,
                name: action.componentState
            });
        case ActionTypes.LOGIN_USER:
            let dealerData = (action.componentState.data.dealer_data && action.componentState.data.dealer_data[0])
                ? action.componentState.data.dealer_data[0]
                : {};
            return({
                ...state,
                userData: action.componentState.data.user_data,
                locality: action.componentState.locality,
                userLoggedIn: 1,
                authToken: action.componentState.data.token,
                dealerData: dealerData,
                dealer_id: (dealerData && dealerData.dealer_id) ? dealerData.dealer_id : null
            });
        case ActionTypes.LOGOUT_USER:
            return ({
                ...initialState
            });
        default : 
        return state;
    }
}

export default defaultReducer;