import React, { Component } from 'react';
import Select from 'react-select';
import InputField from './../../elements/Input';
// import CheckBox from './../../elements/CheckBox';//need to remove
// import Radiobox from './../../elements/Radiobox';//need to remove

const city = [
    { value: '125', label: 'New Delhi' },
    { value: '227', label: 'Bangalore' },
    { value: '336', label: 'Mumbai' },
    { value: '471', label: 'Chennai' },
    { value: '344', label: 'Pune' },
    { value: '8', label: 'Hyderabad' },
    { value: '162', label: 'Gurgaon' }

];
const search_by = [
    { value: '125', label: 'New Delhi' },
    { value: '227', label: 'Bangalore' },
    { value: '336', label: 'Mumbai' },
    { value: '471', label: 'Chennai' },
    { value: '344', label: 'Pune' },
    { value: '8', label: 'Hyderabad' },
    { value: '162', label: 'Gurgaon' }

];
const dc_skus_id = [
    { value: '125', label: 'New Delhi' },
    { value: '227', label: 'Bangalore' },
    { value: '336', label: 'Mumbai' },
    { value: '471', label: 'Chennai' },
    { value: '344', label: 'Pune' },
    { value: '8', label: 'Hyderabad' },
    { value: '162', label: 'Gurgaon' }

];
class AdminDealerList extends Component {
    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleChangetext = this.handleChangetext.bind(this);
        this.state = {
            key: 1,
            city: "",
            state: "",
            location: "",
            dealership:"",
            business:"",
            startDate: new Date(),
            popoverInventory:false,
        };
    }

   
    popoverShow = () => { 
        this.setState({ popoverInventory: true });
    }
    popoverClose = () => { 
        this.setState({ popoverInventory: false });
    }

    // handleChange = date => {//need to remove as it was not in use
    //     this.setState({
    //         startDate: date
    //     });
    // };
    
    handleSelect(key) {
        this.setState({ key });
    }

    handleChange = (sname, ovalue) => {
        this.setState({
            [sname]: ovalue.value
        });

    }

    handleChangetext = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    
    render() {
        
        return (
            <div className="container-fluid ">
                <div className="addStock">
                    <h1>Dealer Listing</h1>
                    <div className="search-wrap">
                        <div className="row">
                        <div className="col-sm-6 col-md-2">
                            <label>Search By</label>                
                            <Select
                                components={{ IndicatorSeparator:() => null }}
                                id="search_by"
                                // value={this.state.search_by}//need to remove as it is duplicate props
                                onChange={this.handleChange.bind(this, 'search_by')}
                                options={search_by}
                                name="search_by"
                                placeholder="search_by"
                                value={search_by.filter(({ value }) => value === this.state.search_by)}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ value }) => value}
                            />
                        </div>

                        <div className="col-sm-6 col-md-2">
                        <InputField
                        id ="search_text"
                        type= "text"
                        placeholder="Enter Keyword"
                        name= "search_text"
                        autocompleate= "off"
                        label="Enter Keyword"
                        
                            //   onChange={this.handleOptionChange.bind(this, 'search_text')}
                            />
                        </div>
                        <div className="col-sm-6 col-md-2">
                            <label>City*</label>
                                <Select
                                    components={{ IndicatorSeparator:() => null }}
                                    id="city"
                                    // value={this.state.city}//need to remove as it is duplicate props
                                    onChange={this.handleChange.bind(this, 'city')}
                                    options={city}
                                    name="city"
                                    placeholder="City"
                                    value={city.filter(({ value }) => value === this.state.city)}
                                    getOptionLabel={({ label }) => label}
                                    getOptionValue={({ value }) => value}
                                />
                            </div>

                            <div className="col-sm-6 col-md-2">
                                <label>Search by sku</label> 
                                <Select
                                    components={{ IndicatorSeparator:() => null }}
                                    id="dc_skus_id"
                                    // value={this.state.dc_skus_id}//need to remove as duplicate props exist
                                    onChange={this.handleChange.bind(this, 'dc_skus_id')}
                                    options={dc_skus_id}
                                    name="dc_skus_id"
                                    placeholder="dc_skus_id"
                                    value={dc_skus_id.filter(({ value }) => value === this.state.dc_skus_id)}
                                    getOptionLabel={({ label }) => label}
                                    getOptionValue={({ value }) => value}
                                />
                            </div>

                        <div className="col-sm-6 col-md-2">
                            <label></label>
                            <div> <button type="submit" className="btn btn-primary mrg-r10" onClick={this.submitFilterForm}>Search</button><button type="reset" className="btn btn-default">Reset</button>
                            </div>
                        </div>
                        </div>
                    </div>                  
                    <div className="result-wrap">                      
                      <div className="card">
                            <div className="pad-15">
                                <div className="row">
                                    <div className="col-sm-8">
                                        <ul className="list-inline nav-tabs">
                                            <li className="nav-item active"><div>Active<span>(2)</span></div></li>
                                            <li className="nav-item"><div>Inactive<span>(20)</span></div></li>
                                            <li className="nav-item"><div>All<span>(3)</span></div></li>
                                            <li className="nav-item"><div>Expiring<span>(3)</span></div></li>
                                            <li className="nav-item"><div>Incomplete<span>(3)</span></div></li>
                                            <li className="nav-item"><div>Pending<span>(3)</span></div></li>
                                            <li className="nav-item"><div>Rejected<span>(3)</span></div></li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-4 text-right pad-t10">
                                    <button type="button" className="btn btn-link mrg-r30" title="Other Settings">Export Excel</button>
                                    <button type="button" className="btn btn-link" title="Other Settings">Add Dealer</button>
                                    </div>
                                </div>
                            </div>
                            <div className="clearfix">
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>GCD</th>
                                                <th>Details</th>
                                                <th>City</th>
                                                <th>Subscribed To</th>
                                                <th>Expiring On</th>
                                                <th>Actions</th>
                                                <th>Active</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td>GCD10000</td>
                                                <td>
                                                    <div>IMF Carz</div>
                                                    <div>info.imfassociates@gmail.com</div>
                                                    <div>8686428800</div>
                                                    <div><img src="https://dealeradmin.gaadi.com/admin/public/images/gaadi.png" alt=""/></div>
                                                </td>
                                                <td>Hyderabad</td>
                                                <td>
                                                    <span className="label green-status mrg-r5">Finance</span>
                                                    <span className="label green-status mrg-r5">Insurance</span>
                                                    <span className="label green-status mrg-r5">Auction_ins</span>
                                                </td>
                                                <td>NA</td>
                                                <td>
                                                    <div className="btn-group btn-group-small mrg-r10 icons-normal">
                                                        <button type="button" className="btn btn-default" title="Open Dashboard"><i className="ic-remove_red_eyevisibility"></i></button>
                                                        <button type="button" className="btn btn-default" title="Edit Dealership"><i className="ic-createmode_editedit"></i></button>
                                                        <button type="button" className="btn btn-default" title="Edit Subscription"><i className="ic-subscription"></i></button>
                                                        <button type="button" className="btn btn-default" title="Contact Setting"><i className="ic-call"></i></button>
                                                        <button type="button" className="btn btn-default" title="Other Settings"><i className="ic-settings"></i></button>
                                                        <button type="button" className="btn btn-default" title="Inventory Record"><i className="ic-insert_drive_file"></i></button>
                                                    </div>
                                                </td>
                                                <td>
                                                    <label className="switch-btn btn btn-link" htmlFor="openhrs">
                                                        <input className="switch-btn " id="openhrs" value="open" name="open[]" type="checkbox"  />
                                                        <div className="slider round"></div>
                                                        <span className="switch-label"></span>
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>GCD10000</td>
                                                <td>
                                                    <div>IMF Carz</div>
                                                    <div>info.imfassociates@gmail.com</div>
                                                    <div>8686428800</div>
                                                    <div><img src="https://dealeradmin.gaadi.com/admin/public/images/gaadi.png" alt="" /></div>
                                                </td>
                                                <td>Hyderabad</td>
                                                <td>
                                                    <span className="label green-status mrg-r5">Finance</span>
                                                    <span className="label green-status mrg-r5">Insurance</span>
                                                    <span className="label green-status mrg-r5">Auction_ins</span>
                                                </td>
                                                <td>NA</td>
                                                <td>
                                                <div className="btn-group btn-group-small mrg-r10 icons-normal">
                                                        <button type="button" className="btn btn-default" title="Open Dashboard"><i className="ic-remove_red_eyevisibility"></i></button>
                                                        <button type="button" className="btn btn-default" title="Edit Dealership"><i className="ic-createmode_editedit"></i></button>
                                                        <button type="button" className="btn btn-default" title="Edit Subscription"><i className="ic-subscription"></i></button>
                                                        <button type="button" className="btn btn-default" title="Contact Setting"><i className="ic-call"></i></button>
                                                        <button type="button" className="btn btn-default" title="Other Settings"><i className="ic-settings"></i></button>
                                                        <button type="button" className="btn btn-default" title="Inventory Record"><i className="ic-insert_drive_file"></i></button>
                                                    </div>
                                                </td>
                                                <td>
                                                    <label className="switch-btn btn btn-link" htmlFor="openhrs">
                                                        <input className="switch-btn " id="openhrs" value="open" name="open[]" type="checkbox" checked="checked"  />
                                                        <div className="slider round"></div>
                                                        <span className="switch-label"></span>
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>GCD10000</td>
                                                <td>
                                                    <div>IMF Carz</div>
                                                    <div>info.imfassociates@gmail.com</div>
                                                    <div>8686428800</div>
                                                    <div><img src="https://dealeradmin.gaadi.com/admin/public/images/gaadi.png" alt="" /></div>
                                                </td>
                                                <td>Hyderabad</td>
                                                <td>
                                                    <span className="label green-status mrg-r5">Finance</span>
                                                    <span className="label green-status mrg-r5">Insurance</span>
                                                    <span className="label green-status mrg-r5">Auction_ins</span>
                                                </td>
                                                <td>
                                                    June-12-2020<div className="f12 text-light">(Buyer Listing) in 221 Days</div>
                                                </td>
                                                <td>
                                                    <div className="btn-toolbar">
                                                    <div className="btn-group btn-group-small mrg-r10 icons-normal">
                                                        <button type="button" className="btn btn-default" title="Open Dashboard"><i className="ic-remove_red_eyevisibility"></i></button>
                                                        <button type="button" className="btn btn-default" title="Edit Dealership"><i className="ic-createmode_editedit"></i></button>
                                                        <button type="button" className="btn btn-default" title="Edit Subscription"><i className="ic-subscription"></i></button>
                                                        <button type="button" className="btn btn-default" title="Contact Setting"><i className="ic-call"></i></button>
                                                        <button type="button" className="btn btn-default" title="Other Settings"><i className="ic-settings"></i></button>
                                                    </div>
                                                    <div className="btn-group btn-group-small mrg-r10 icons-normal">
                                                        <button type="button" onClick={() => this.setState({ popoverInventory: !this.state.popoverInventory })}  className="btn btn-default" title="Inventory Record"><i className="ic-insert_drive_file"></i></button>
                                                        
                                                        { this.state.popoverInventory ? 
                                                        <div className="popover bs-popover-bottom" id="popoverInventory">                                                            
                                                        <span className="arrow"></span>
                                                        <h3 className="popover-header">Active Inventory:47
                                                        <span className="close" onClick={this.popoverClose}><i className="sprite ic-close-b"></i></span></h3>
                                                        <div className="popover-body">
                                                            <div className="mrg-b15">
                                                                <div><strong>Classified Details</strong></div>
                                                                <div>Classified Done: 0</div>
                                                                <div>Classified Done: 0</div>
                                                            </div>
                                                            <div className="mrg-b15">
                                                                <div><strong>Classified Details</strong></div>
                                                                <div>Classified Done: 0</div>
                                                                <div>Classified Done: 0</div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                        : null }
                                                        
                                                    </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <label className="switch-btn btn btn-link" htmlFor="openhrs">
                                                        <input className="switch-btn " id="openhrs" value="open" name="open[]" type="checkbox" checked="checked" />
                                                        <div className="slider round"></div>
                                                        <span className="switch-label"></span>
                                                    </label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminDealerList;
//export default ManageDealerList;
