import React from 'react';
//import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { GOOGLE_MAP_API_KEY} from './../../../config/constant';
const libraries = ['places'];
const mapContainerStyle = {
  width: '94%',
  height: '600px',
};
const GMapComponent = (props) => {
  //   let center = {
  //       lat: props.lat,
  //       lng: props.lng
  //     };
  // const { isLoaded, loadError } = useLoadScript({
  //   googleMapsApiKey: GOOGLE_MAP_API_KEY,
  //   libraries,
  // });
  // if (loadError) {
  //   return <div>Error loading maps</div>;
  // }
  // if (!isLoaded) {
  //   return <div>Loading maps</div>;
  // }
  return (
    <div>
      Loading maps...
    </div>
  );
};
export default GMapComponent;